import { useQuery } from '@tanstack/react-query';
import { KeyFactory } from '../../services-base/key-factory';
import { callApiGetCompany } from '../../api/myCompany';

export type CompanyManagementProps = {
  page: number;
  size: number;
  companyName: string;
  customerEmail: string;
  serviceNation: string;
};

export const useApiGetCompany = (parmas: CompanyManagementProps) => {
  return useQuery({
    queryKey: KeyFactory.getCompany(parmas.page, parmas.size),
    queryFn: () => callApiGetCompany(parmas),
  });
};
