import { FormFieldTextArea } from '../FormFieldArea';
import { FormFieldText } from '../FormFieldText';
import { useTranslation } from 'react-i18next';
import { LandingContactUsData } from '../../api/types';

interface IProps {
  data?: LandingContactUsData;
  onChange: (value: LandingContactUsData) => void;
}

const LandingContactUs = (props: IProps) => {
  const { data, onChange } = props;
  const { t } = useTranslation();
  return (
    <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
      <span className="font-bold text-[18px]">{t('Contact Us')}</span>
      <div className="w-1/3">
        <FormFieldText
          id={'titleHome'}
          label="Title"
          value={data?.CONTACT_US?.title}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp.CONTACT_US) return;
            temp.CONTACT_US.title = e;
            onChange(temp);
          }}
          maxLength={100}
          placeholder="Enter title"
          validateCaller={{}}
        />
      </div>
      <FormFieldTextArea
        label={t('Description')}
        className="w-full"
        id="description"
        placeholder="Enter description"
        value={data?.CONTACT_US?.description}
        row={3}
        maxLength={1000}
        onChange={(e) => {
          const temp = { ...data };
          if (!temp.CONTACT_US) return;
          temp.CONTACT_US.description = e;
          onChange(temp);
        }}
        validateCaller={{}}
      />
      <div className="grid grid-cols-3 gap-4">
        <div className=" col-span-1">
          <FormFieldText
            id={'Address'}
            label="Address"
            value={data?.CONTACT_US?.address}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.CONTACT_US) return;
              temp.CONTACT_US.address = e;
              onChange(temp);
            }}
            maxLength={255}
            placeholder="Enter address"
            validateCaller={{}}
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            id={'Phone Number'}
            label="Phone Number"
            value={data?.CONTACT_US?.phone}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.CONTACT_US) return;
              temp.CONTACT_US.phone = e;
              onChange(temp);
            }}
            maxLength={255}
            placeholder="Enter phone number"
            validateCaller={{}}
          />
        </div>
        <div className="col-span-1">
          <FormFieldText
            id={'Email'}
            label="Email"
            value={data?.CONTACT_US?.email}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.CONTACT_US) return;
              temp.CONTACT_US.email = e;
              onChange(temp);
            }}
            maxLength={255}
            placeholder="Enter email"
            validateCaller={{}}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingContactUs;
