import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidate } from '../hooks-ui/useValidateCaller';
import { FormFieldProps } from '../types/common';
import clsx from 'clsx';
import { addThousandSeparator } from '../helpers/helpers';

export function FormFieldText(props: FormFieldProps<string>) {
  const translation = useTranslation();
  const [shouldShowError, setShouldShowError] = useValidate(
    props.id,
    props.isRequired,
    props.value,
    props.validateCaller,
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;
    if (props.thousandSeparator) {
      const regex = /^-?\d*\.?\d*$/;
      const isValidDecimal = regex.test(text);
      if (!isValidDecimal) return;
    }
    props.onChange(text);
  }

  const handleBlur = (value: string) => {
    setShouldShowError.bind(undefined, !isTextValid);
    if (!props.thousandSeparator) return;
    props.onChange(addThousandSeparator(value));
  };

  const handleFocus = (value: string) => {
    setShouldShowError.bind(undefined, false);
    if (!props.thousandSeparator) return;
    const newValue = value.replaceAll(',', '');
    props.onChange(newValue);
  };

  const isTextValid = !props.isRequired || !!props.value;
  const statusClassName = shouldShowError
    ? 'border-danger bg-red-50'
    : 'bg-white';
  return (
    <div className={clsx('flex flex-col gap-2', props.className)}>
      {!!props.label && (
        <p className="flex text-cBase font-bold gap-1">
          <span>{translation.t(props.label)}</span>
          {props.isRequired && <span className="text-danger">*</span>}
        </p>
      )}
      <input
        type="text"
        value={props.value || ''}
        onChange={handleChange}
        onFocus={(e) => handleFocus(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        className={clsx(
          'w-full h-[40px] rounded-lg border py-1 px-2',
          statusClassName,
          props.isFixedValue ? 'pointer-events-none' : '',
        )}
        readOnly={props.isFixedValue}
      />
    </div>
  );
}
