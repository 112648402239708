import { RequestManagementProps } from '../hooks/api/request';
import { callApi } from '../services-base/api';
import { RawPagingApiResult, RequestManagementResponese } from './types';

export async function callApiGetRequest(
  body: RequestManagementProps,
  path: string,
) {
  const pathURL = `/api/landing-page/${path}`;
  const rawResult = await callApi<
    RawPagingApiResult<RequestManagementResponese>
  >('POST', pathURL, body, true);
  return rawResult;
}

export async function callApiUpdateRequest(
  body: {
    status?: string;
    pic?: string;
    id?: number;
  },
  path: string,
) {
  const pathURL = `/api/landing-page/${path}`;
  await callApi<RawPagingApiResult<RequestManagementResponese>>(
    'POST',
    pathURL,
    body,
    true,
  );
}
