import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { generateFormatDate } from '../services-ui/date';

export const transferColumns: GridColDef[] = [
  {
    field: 'rowIndex',
    headerName: 'No.',
    width: 50,
    valueGetter: (params) =>
      params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
  },
  {
    field: 'companyName',
    headerName: 'Company Name',
    sortable: true,
    width: 150,
  },
  {
    field: 'incorporationDate',
    headerName: 'Incorporation Date',
    sortable: true,
    type: 'string',
    width: 150,
    valueGetter: (params: GridValueGetterParams<any>) => {
      return generateFormatDate(new Date(params.row.incorporationDate));
    },
  },
];

export const referColumns: GridColDef[] = [
  {
    field: 'rowIndex',
    headerName: 'No.',
    width: 100,
    valueGetter: (params) =>
      params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
  },
  {
    field: 'refererType',
    headerName: 'Referral Type',
    width: 200,
    sortable: true,
    valueGetter: (params) => {
      const value = params.row.type === 1 ? 'Individual' : 'Corporation';
      return value;
    },
  }
];

export const coreColumns: GridColDef[] = [
  {
    field: 'rowIndex',
    headerName: 'No.',
    width: 100,
    valueGetter: (params) =>
      params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
  },
  
  {
    field: 'state',
    headerName: 'States',
    sortable: true,
    width: 200,
  }
];

export const addOnColumns: GridColDef[] = [
  {
    field: 'rowIndex',
    headerName: 'No.',
    width: 100,
    valueGetter: (params) =>
      params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
  },
  {
    field: 'nation',
    headerName: 'Nation',
    sortable: true,
    width: 200,
  }
];
