import { toast } from "react-toastify";
import { API_DOMAIN, EXPIRATION_TIME_FOR_ACCESS_TOKEN, EXPIRATION_TIME_FOR_REFRESH_TOKEN } from "../_loadEnv";
import { isNotNullish } from "../utils/typeCheck";
import { TAB_EXPORT } from "../constants/global";

type ApiMethod = 'GET' | 'POST' | 'PUT'
type ResponseOk<T = unknown> = {
  "status": string,
  "message": string,
  "data": T
}
const AUTH_ERROR_STATUS = 401 as const
type ResponseError<T = unknown> = {
  "status": typeof AUTH_ERROR_STATUS | number,
  "message": string,
}

const LOGIN_PATH = '/login'

export async function callApi<T>(method: ApiMethod, path: string, paramsOrBody: Record<string, any>, isPrivateApi: boolean = false): Promise<T> {
  const apiUrl = new URL(path, API_DOMAIN)
  if (method === 'GET') {
    addParamsToSearchParams(apiUrl.searchParams, paramsOrBody)
  }
  const body = method === "GET" ? undefined : JSON.stringify(paramsOrBody)
  const contentType = method === "GET" ? undefined : {
    'Content-Type': 'application/json',
  }

  let authorization = undefined
  if (isPrivateApi) {
    const accessTokenInfo = await getAccessTokenInfo()
    const auth = accessTokenInfo && getAuthorizationString(accessTokenInfo)
    if (auth) {
      authorization = {
        'Authorization': getAuthorizationString(accessTokenInfo)
      }
    } else {
      // Refresh token was expired
      alert('Please login again!')
      window.open(LOGIN_PATH, '_self')
      throw new Error('User is no longer logged in')
    }
  }

  const response = await fetch(
    apiUrl,
    {
      method,
      headers: {
        ...authorization,
        ...contentType,
      },
      body: body,
    }
  )
  if (!response.ok) {
    const res: ResponseError = await response.json()
    if (res.status === AUTH_ERROR_STATUS) {
      // token was invalid
      alert('Please login again!')
      window.open(LOGIN_PATH, '_self')
    }
    throw new Error(res.message)
  }
  const responseObject: ResponseOk<T> = await response.json()
  if (responseObject.status !== '200') {
    throw new Error(responseObject.message)
  }
  return responseObject.data
}

export async function getAccessTokenInfo() {
  const accessTokenInfo = getToken('accessToken')
  if (accessTokenInfo) {
    return accessTokenInfo
  }
  const refreshTokenInfo = getToken('refreshToken')
  if (refreshTokenInfo) {
    return await refreshToken(refreshTokenInfo.token)
  }
  return null
}

export function getAuthorizationString(accessTokenInfo: TokenInfo) {
  if (accessTokenInfo.type === 'Bearer') {
    return `Bearer ${accessTokenInfo.token}`
  }
  return accessTokenInfo.token
}

type ResultOfRefreshToken = {
  accessToken: string,
  tokenType: string,
  refreshToken: string,
}
async function refreshToken(refreshToken: string) {
  const path = 'api/auth/refreshtoken'
  const refreshApiUrl = new URL(path, API_DOMAIN)
  const response = await fetch(refreshApiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ refreshToken })
  });

  if (!response.ok) {
    throw new Error('Failed to refresh token');
  }

  const result = (await response.json()).data as ResultOfRefreshToken;
  saveToken(result.accessToken, result.tokenType, result.refreshToken)
  return generateTokenInfo(result.accessToken, result.tokenType, EXPIRATION_TIME_FOR_ACCESS_TOKEN)
}

type TokenName = 'accessToken' | 'refreshToken'

type TokenInfo = {
  token: string,
  expiredAt: number,
  type: string
}

function generateTokenInfo(token: string, type: string, expiredTime: number) {
  const DELAY_TIME = 10_000
  return {
    token: token,
    type: type,
    expiredAt: new Date().valueOf() + expiredTime - DELAY_TIME
  }
}

export function saveToken(accessToken: string, accessTokenType: string, refreshToken: string) {
  const accessTokenInfo = generateTokenInfo(accessToken, accessTokenType, EXPIRATION_TIME_FOR_ACCESS_TOKEN)
  const refreshTokenInfo = generateTokenInfo(refreshToken, '', EXPIRATION_TIME_FOR_REFRESH_TOKEN)
  sessionStorage.setItem("accessToken", JSON.stringify(accessTokenInfo))
  localStorage.setItem("refreshToken", JSON.stringify(refreshTokenInfo)) // should save refresh token to cookie
}

export function removeAuthToken() {
  sessionStorage.removeItem("accessToken")
  localStorage.removeItem("refreshToken")
}

export function getToken(tokenName: TokenName) {
  const tokenInfoString = tokenName === "accessToken"
    ? sessionStorage.getItem('accessToken')
    : localStorage.getItem('refreshToken')
  if (!tokenInfoString) {
    return null
  }
  const tokenInfo = JSON.parse(tokenInfoString) as TokenInfo
  const now = new Date().valueOf()
  if (+tokenInfo.expiredAt < now) {
    return null
  }
  return tokenInfo
}

function addParamsToSearchParams(urlSearchParams: URLSearchParams, params: Record<string, any>) {
  Object.keys(params).forEach(key => {
    if (isNotNullish(params[key])) {
      if (typeof params[key] !== "object") {
        urlSearchParams.append(key, String(params[key]).trim())
      } else {
        for (const keyOfParamsKey in params[key]) {
          urlSearchParams.append(`${key}[${keyOfParamsKey}]`, String(params[key][keyOfParamsKey]).trim())
        }
      }
    }
  })
}

export const handleExportData = async (tabName: string) => {
  const path = `/api/export/${tabName}`;
  let pathFileName = '';
  const accessTokenInfo = await getAccessTokenInfo();
  const auth = accessTokenInfo && getAuthorizationString(accessTokenInfo);
  let authorization;
  if (auth) {
    authorization = {
      Authorization: getAuthorizationString(accessTokenInfo),
    };
  }
  switch (tabName) {
    case TAB_EXPORT.SERVICE:
      pathFileName = 'paid_service';
      break;
    case TAB_EXPORT.USER:
      pathFileName = 'user';
      break;
    case TAB_EXPORT.ORDER_PAYMENT:
      pathFileName = 'order_payment';
      break;
    case TAB_EXPORT.KYC_REQUEST:
      pathFileName = 'KYC_request';
      break;
    default:
      break;
  }
  const apiUrl = new URL(path, API_DOMAIN);
  fetch(apiUrl, {
    method: 'POST',
    headers: {
      ...authorization,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('multipart')) {
        return response.blob();
      } else {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          const currentTime = new Date().getTime();
          const fileName = `${pathFileName}_${currentTime}.xlsx`;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
      }
      toast.success('Successfully exported');
    })
    .catch((error) => {
      toast.error('Error. Please try again!');
    });
};