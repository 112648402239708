import { Listbox } from '@headlessui/react';
import { ChangeEvent, useState } from 'react';
import { uploadFile } from '../api/upload';
import { FormFieldProps } from '../types/common';
import { cn } from '../utils/cn.util';
import { IconAltArrowDown, IconUpload, IconXCircle } from './icons';
import InputFile from './InputFile';

export type File = {
  id: string;
  name: string;
  url: string;
};

export function FormFieldMultipleUpload({
  id,
  isRequired,
  value = [],
  label,
  isFixedValue,
  onChange,
  maxFiles,
}: FormFieldProps<File[]> & { maxFiles?: number }) {
  const [files, setFiles] = useState<File[]>(value);
  const [open, setOpen] = useState<boolean>(false);

  const handleUploadFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const tempfiles = event.currentTarget.files;
    if (!event.currentTarget.files || !tempfiles) return;

    if (maxFiles && files.length >= maxFiles) return;

    const res = await uploadFile(event.currentTarget.files);
    const newFiles = [...files];
    res.data.forEach((value, index) => {
      newFiles.push({
        id: value,
        name: tempfiles?.item(index)?.name ?? '',
        url: value,
      });
    });
    setFiles(newFiles);
    onChange?.(newFiles);
  };

  return (
    <div className="flex flex-col gap-2">
      {!!label && (
        <p className="flex text-cBase font-bold gap-1">
          <span>{label}</span>
          {isRequired && <span className="text-danger">*</span>}
        </p>
      )}

      <div
        className={cn('rounded-lg h-10', {
          'border border-solid border-surface': !isFixedValue,
        })}
      >
        <Listbox>
          <div className="flex items-center h-full gap-1 w-full">
            <Listbox.Button
              className="grow text-left flex items-center line-clamp-1"
              onClick={() => setOpen(!open)}
            >
              <IconAltArrowDown
                className={`mx-2 shrink-0 ${open ? 'rotate-180' : ''}`}
              />
              {`${files.length} ${files.length > 1 ? 'files' : 'file'} uploaded`}
            </Listbox.Button>

            {!isFixedValue && (
              <label htmlFor={id} className="cursor-pointer">
                <IconUpload className="mx-2" />
                <input
                  id={id}
                  type="file"
                  className="hidden"
                  onChange={handleUploadFileChange}
                  multiple
                  accept="*"
                />
              </label>
            )}
          </div>

          {open && (
            <div className="shadow rounded-lg bg-white translate-y-2 max-h-[200px] overflow-y-auto">
              {files?.map((file, idx) => (
                <div key={idx} className="flex ">
                  <InputFile
                    key={`file${file.id}`}
                    onChange={() => {
                      const newFiles = files.filter((f) => f.id !== file.id);
                      setFiles(newFiles);
                      onChange?.(newFiles);
                    }}
                    fileName={file.id || undefined}
                    maxSize={10}
                    accept="*"
                    isFullSize
                    canDeleteFile={!isFixedValue}
                    hiddenUploadBtn={true}
                  />
                </div>
              ))}
            </div>
          )}
        </Listbox>
      </div>
    </div>
  );
}
