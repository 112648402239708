import { useQuery } from '@tanstack/react-query';
import {
  callApi,
  getAccessTokenInfo,
  getAuthorizationString,
} from '../services-base/api';
import { mergeQueryOptions } from '../services-base/reactQuery/queryOption';
import { FilesNameResponese } from './types';

export type UploadResponse = {
  message: string;
  status: string;
  data: string[];
};

export const uploadFile = async (
  files: FileList,
  payload?: Record<string, string | number | boolean>,
  path?: string,
) => {
  const headers = new Headers();
  headers.append(
    'Authorization',
    getAuthorizationString((await getAccessTokenInfo())!),
  );

  const formData = new FormData();

  for (const [key, file] of Object.entries(files)) {
    formData.append('files', file);
  };

  payload &&
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value.toString());
    });

  const options = {
    method: 'POST',
    headers: headers,
    body: formData,
  };

  const endpoint = path
    ? `${process.env.REACT_APP_URL}${path}`
    : `${process.env.REACT_APP_URL}/api/file/upload`;

  const data = (await fetch(endpoint, options).then((response) =>
    response.json(),
  )) as UploadResponse;

  return data;
};

export const getFile = async (
  name: string,
  opts: { download?: boolean } = { download: true },
) => {
  const headers = new Headers();
  headers.append(
    'Authorization',
    getAuthorizationString((await getAccessTokenInfo())!),
  );

  const options = {
    method: 'GET',
    headers: headers,
  };

  const endpoint = `${process.env.REACT_APP_URL}/api/file/files/${name}`;
  let fileName = '';
  const blob = await fetch(endpoint, options)
    .then((response) => {
      const contentDisposition = response.headers.get('Content-Disposition');
      const match = contentDisposition?.match(/filename="(.+)"/);
      if (match) fileName = match[1];
      return response.blob();
    })
    .then((blob) => {
      if (!opts?.download) return blob;

      const a = document.createElement('a');
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    })
    .catch((error) => {});

  return blob;
};

export const deleteFileContract = async (
  fileId: string,
  idPaidService: number,
) => {
  const path = '/api/file/delete-final-contract';
  await callApi('POST', path, { fileId, idPaidService }, true);
};

export const getFileName = async (fileIds: string[]) => {
  const path = '/api/file/info';
  return await callApi<FilesNameResponese[]>('POST', path, fileIds, true);
};

export function useApiGetFileName(param: string[], enable: boolean) {
  return useQuery(
    mergeQueryOptions({
      queryKey: param,
      queryFn: () => getFileName(param),
      enabled: enable
    }),
  );
}
