import { useTranslation } from 'react-i18next';
import { FormFieldText } from '../FormFieldText';
import { FormFieldTextArea } from '../FormFieldArea';
import { LandingHomeData } from '../../api/types';

interface IProps {
  data?: LandingHomeData;
  onChange: (value: LandingHomeData) => void;
}

const LandingHome = (props: IProps) => {
  const { data, onChange } = props;
  const { t } = useTranslation();

  const renderHome = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Main Title')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.MAIN_TITLE?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.MAIN_TITLE) return;
              temp.MAIN_TITLE.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.MAIN_TITLE?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp.MAIN_TITLE) return;
            temp.MAIN_TITLE.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
      </div>
    );
  };

  const renderOurService = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Our Services')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.OUR_SERVICES?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.OUR_SERVICES) return;
              temp.OUR_SERVICES.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className=" col-span-1">
            <FormFieldText
              id={'Subtitle 1'}
              label="Subtitle 1"
              value={data?.OUR_SERVICES?.value?.[0].title}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.OUR_SERVICES?.value?.[0]) return;
                temp.OUR_SERVICES.value[0].title = e;
                onChange(temp);
              }}
              maxLength={100}
              placeholder="Enter subtitle"
              validateCaller={{}}
            />
          </div>
          <div className="col-span-1">
            <FormFieldText
              id={'Subtitle 2'}
              label="Subtitle 2"
              value={data?.OUR_SERVICES?.value?.[1].title}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.OUR_SERVICES?.value?.[1]) return;
                temp.OUR_SERVICES.value[1].title = e;
                onChange(temp);
              }}
              maxLength={100}
              placeholder="Enter subtitle"
              validateCaller={{}}
            />
          </div>
          <div className="col-span-1">
            <FormFieldText
              id={'Subtitle 3'}
              label="Subtitle 3"
              value={data?.OUR_SERVICES?.value?.[2].title}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.OUR_SERVICES?.value?.[2]) return;
                temp.OUR_SERVICES.value[2].title = e;
                onChange(temp);
              }}
              maxLength={100}
              placeholder="Enter subtitle"
              validateCaller={{}}
            />
          </div>
          <div className="col-span-1">
            <FormFieldTextArea
              label={t('Description 1')}
              className="w-full"
              id="description1"
              placeholder="Enter description"
              value={data?.OUR_SERVICES?.value?.[0].description}
              row={4}
              maxLength={1000}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.OUR_SERVICES?.value?.[0]) return;
                temp.OUR_SERVICES.value[0].description = e;
                onChange(temp);
              }}
              validateCaller={{}}
            />
          </div>
          <div className=" col-span-1">
            <FormFieldTextArea
              label={t('Description 2')}
              className="w-full"
              id="description2"
              placeholder="Enter description"
              value={data?.OUR_SERVICES?.value?.[1].description}
              row={4}
              maxLength={1000}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.OUR_SERVICES?.value?.[1]) return;
                temp.OUR_SERVICES.value[1].description = e;
                onChange(temp);
              }}
              validateCaller={{}}
            />
          </div>
          <div className=" col-span-1">
            <FormFieldTextArea
              label={t('Description 3')}
              className="w-full"
              id="description3"
              placeholder="Enter description"
              value={data?.OUR_SERVICES?.value?.[2].description}
              row={4}
              maxLength={1000}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.OUR_SERVICES?.value?.[2]) return;
                temp.OUR_SERVICES.value[2].description = e;
                onChange(temp);
              }}
              validateCaller={{}}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPricing = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Pricing')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.PRICING?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp?.PRICING) return;
              temp.PRICING.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.PRICING?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp?.PRICING) return;
            temp.PRICING.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
      </div>
    );
  };

  const renderAddOnServices = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Add-on Services')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.ADDON_SERVICE?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp?.ADDON_SERVICE) return;
              temp.ADDON_SERVICE.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.ADDON_SERVICE?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp?.ADDON_SERVICE) return;
            temp.ADDON_SERVICE.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
      </div>
    );
  };

  const renderAboutUs = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('About Us')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.ABOUT_US?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp?.ABOUT_US) return;
              temp.ABOUT_US.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.ABOUT_US?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp?.ABOUT_US) return;
            temp.ABOUT_US.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
        <FormFieldTextArea
          label={t('Short Message')}
          className="w-full"
          id="description"
          placeholder="Enter short message"
          value={data?.ABOUT_US?.shortMessage}
          row={3}
          maxLength={2000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp?.ABOUT_US) return;
            temp.ABOUT_US.shortMessage = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
      </div>
    );
  };

  const renderTestimonials = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Testimonials')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.TESTIMONIALS?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp?.TESTIMONIALS) return;
              temp.TESTIMONIALS.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className=" col-span-1">
            <FormFieldText
              id={'Customer 1'}
              label="Customer 1"
              value={data?.TESTIMONIALS?.value?.[0]?.title}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.TESTIMONIALS?.value?.[0]) return;
                temp.TESTIMONIALS.value[0].title = e;
                onChange(temp);
              }}
              maxLength={100}
              placeholder="Enter customer name"
              validateCaller={{}}
            />
          </div>
          <div className="col-span-1">
            <FormFieldText
              id={'Customer 2'}
              label="Customer 2"
              value={data?.TESTIMONIALS?.value?.[1]?.title}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.TESTIMONIALS?.value?.[1]) return;
                temp.TESTIMONIALS.value[1].title = e;
                onChange(temp);
              }}
              maxLength={100}
              placeholder="Enter customer name"
              validateCaller={{}}
            />
          </div>
          <div className="col-span-1">
            <FormFieldTextArea
              className="w-full"
              id="description1"
              placeholder="Enter description"
              value={data?.TESTIMONIALS?.value?.[0]?.description}
              row={4}
              maxLength={1000}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.TESTIMONIALS?.value?.[0]) return;
                temp.TESTIMONIALS.value[0].description = e;
                onChange(temp);
              }}
              validateCaller={{}}
            />
          </div>
          <div className=" col-span-1">
            <FormFieldTextArea
              className="w-full"
              id="description2"
              placeholder="Enter description"
              value={data?.TESTIMONIALS?.value?.[1]?.description}
              row={4}
              maxLength={1000}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.TESTIMONIALS?.value?.[1]) return;
                temp.TESTIMONIALS.value[1].description = e;
                onChange(temp);
              }}
              validateCaller={{}}
            />
          </div>
          <div className=" col-span-1">
            <FormFieldText
              id={'Customer 3'}
              label="Customer 3"
              value={data?.TESTIMONIALS?.value?.[2]?.title}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.TESTIMONIALS?.value?.[2]) return;
                temp.TESTIMONIALS.value[2].title = e;
                onChange(temp);
              }}
              maxLength={100}
              placeholder="Enter customer name"
              validateCaller={{}}
            />
          </div>
          <div className="col-span-1">
            <FormFieldText
              id={'Customer 4'}
              label="Customer 4"
              value={data?.TESTIMONIALS?.value?.[3]?.title}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.TESTIMONIALS?.value?.[3]) return;
                temp.TESTIMONIALS.value[3].title = e;
                onChange(temp);
              }}
              maxLength={100}
              placeholder="Enter customer name"
              validateCaller={{}}
            />
          </div>
          <div className="col-span-1">
            <FormFieldTextArea
              className="w-full"
              id="description1"
              placeholder="Enter description"
              value={data?.TESTIMONIALS?.value?.[2]?.description}
              row={4}
              maxLength={1000}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.TESTIMONIALS?.value?.[2]) return;
                temp.TESTIMONIALS.value[2].description = e;
                onChange(temp);
              }}
              validateCaller={{}}
            />
          </div>
          <div className=" col-span-1">
            <FormFieldTextArea
              className="w-full"
              id="description2"
              placeholder="Enter description"
              value={data?.TESTIMONIALS?.value?.[3]?.description}
              row={4}
              maxLength={1000}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp?.TESTIMONIALS?.value?.[3]) return;
                temp.TESTIMONIALS.value[3].description = e;
                onChange(temp);
              }}
              validateCaller={{}}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderMessage = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">
          {t('A Message from our CEO')}
        </span>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.MESSAGE_CEO?.description}
          row={5}
          maxLength={3000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp?.MESSAGE_CEO) return;
            temp.MESSAGE_CEO.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
      </div>
    );
  };

  return (
    <>
      {renderHome()}
      {renderOurService()}
      {renderPricing()}
      {renderAddOnServices()}
      {renderAboutUs()}
      {renderTestimonials()}
      {renderMessage()}
    </>
  );
};

export default LandingHome;
