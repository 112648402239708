import { useEffect, useState } from 'react';
import { FormFieldText } from '../components/FormFieldText';
import { useTranslation } from 'react-i18next';
import ButtonCs from '../components/ButtonCs';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useApiGetListQuotation } from '../hooks/api/quotation';
import FormDateTime from '../components/FormDateTime';
import dayjs from 'dayjs';
import { StatusBadge } from '../components/StatusBadge';
import { Status } from '../constants/StatusBadge';
import { callApiUpdateQuotation } from '../api/quotationRequest';
import { DialogSuccessFullscreen } from '../components/DialogFormStatusFullscreen';

const DEFAULT_SEARCH = {
  searchStr: '',
};

const QuotationRequest = () => {
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });

  const [dataSearch, setDataSearch] = useState<{
    [key in string]: string;
  }>(DEFAULT_SEARCH);

  const [showNoti, setShowNoti] = useState<boolean>(false);

  const [dataQuotation, setDataQuotation] = useState<{
    [key in string]: {
      price: string;
      dueDate: string;
    };
  }>();

  const { data, refetch } = useApiGetListQuotation({
    page: paginationModel.page,
    size: paginationModel.pageSize,
    searchStr: dataSearch.searchStr,
    fromDate: dataSearch.fromDate,
    toDate: dataSearch.toDate,
  });

  useEffect(() => {
    let dataTemp: any = {};
    data?.content.forEach((item: any) => {
      dataTemp[item.id] = {
        price: item.price,
        dueDate: item.dueDate,
      };
    });
    setDataQuotation(dataTemp);
  }, [data?.content]);

  const QuotationColumns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'No.',
      width: 50,
      valueGetter: (params) =>
        params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      width: 150,
      renderCell: (params) => {
        const status = params.row.status;
        return <StatusBadge status={status as Status}></StatusBadge>;
      },
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      sortable: true,
      type: 'string',
      width: 200,
      renderCell: (params) => {
        const name = params.row.user.firstName + ' ' + params.row.user.lastName;
        return name;
      },
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      sortable: true,
      width: 180,
      renderCell: (params) => {
        const companyName = params.row.company.companyName;
        return companyName;
      },
    },
    {
      field: 'serviceName',
      headerName: 'Service Name',
      sortable: true,
      type: 'string',
      width: 180,
      renderCell: (params) => {
        const serviceName = params.row.masterService.serviceName;
        return serviceName;
      },
    },

    {
      field: 'updatedBy',
      headerName: 'Updated By',
      sortable: true,
      type: 'string',
      width: 200,
    },
    {
      field: 'price',
      headerName: 'Quotation Price ($)',
      sortable: false,
      type: 'string',
      width: 200,
      renderCell: (params) => {
        return (
          <FormFieldText
            id={`price ${params.row.id}`}
            validateCaller={{}}
            onChange={(e) => handleChangeDataTable(e, 'price', params.row.id)}
            value={dataQuotation?.[params.row.id]?.price}
            placeholder={t('Input quotation price')}
          />
        );
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      sortable: true,
      type: 'string',
      width: 200,
      renderCell: (params) => {
        return (
          <FormDateTime
            value={
              dataQuotation?.[params.row.id]?.dueDate
                ? dayjs(dataQuotation?.[params.row.id]?.dueDate)
                : null
            }
            disablePast
            onChange={(value) =>
              handleChangeDataTable(
                value?.format() ?? '',
                'dueDate',
                params.row.id,
              )
            }
          />
        );
      },
    },
    {
      field: 'Option',
      headerName: 'Option',
      sortable: false,
      type: 'string',
      width: 150,
      renderCell: (params) => (
        <ButtonCs
          type="submit"
          onClick={() => {
            handleSendQuotation(params.row.id);
          }}
        >
          {t('Send')}
        </ButtonCs>
      ),
    },
  ];

  const handleChangeDataTable = (value: string, field: string, id: number) => {
    const tempData = { ...dataQuotation };
    if (field === 'price') tempData[id].price = value;
    else tempData[id].dueDate = value;
    setDataQuotation(tempData);
  };

  const handleSendQuotation = async (id: number) => {
    try {
      if (
        !dataQuotation ||
        !dataQuotation[id].price ||
        !dataQuotation[id].dueDate
      )
        return;
      const payload = {
        id,
        price: Number(dataQuotation[id].price),
        dueDate: dataQuotation[id].dueDate,
      };
      await callApiUpdateQuotation(payload);
      setShowNoti(true);
      refetch();
    } catch {}
  };

  const handleReset = () => {
    setDataSearch(DEFAULT_SEARCH);
    setTimeout(refetch);
  };

  const handleSearch = () => {
    refetch();
  };
  return (
    <div className={'w-full grow flex flex-col p-3'}>
      <div
        className={
          'flex flex-col grow overflow-auto bg-white rounded justify-start items-center py-6 px-4 sm:px-8'
        }
      >
        <div className={'text-h4 w-full text-start mb-6'}>
          {t('Quotation Request')}
        </div>
        <div
          className={'w-full grow'}
          key={data?.content?.map((value: any) => value.id).join('_')}
        >
          <div
            className={
              'w-full flex flex-row justify-between items-center gap-10 mb-4'
            }
          >
            <div
              className={
                'w-full flex flex-row justify-start items-end gap-4 mb-4'
              }
            >
              <FormFieldText
                label={t('Customer Name')}
                id="customerNameSearch"
                validateCaller={{}}
                onChange={(e) => setDataSearch({ ...dataSearch, searchStr: e })}
                value={dataSearch.searchStr}
                placeholder={t('Input customer name')}
              />
              <div className="flex gap-2">
                <ButtonCs
                  type="button"
                  className="bg-transparent border border-surface text-primary"
                  onClick={handleReset}
                >
                  {t('Reset')}
                </ButtonCs>
                <ButtonCs type="submit" onClick={handleSearch}>
                  {t('Apply')}
                </ButtonCs>
              </div>
            </div>
          </div>
          <DataGrid
            paginationMode="server"
            rows={data?.content || []}
            columns={QuotationColumns}
            sx={{
              height: 'calc(100vh - 250px)',
            }}
            pageSizeOptions={[25]}
            rowCount={data?.totalElements || 0}
            getRowId={(row) => row.id}
            rowSelection={false}
            paginationModel={paginationModel}
            onPaginationModelChange={(model) => setPaginationModel(model)}
          />
        </div>
      </div>
      {showNoti && (
        <DialogSuccessFullscreen
          title={`Sent Request Successfully`}
          onClose={() => setShowNoti(false)}
        />
      )}
    </div>
  );
};

export default QuotationRequest;
