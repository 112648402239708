import { useContext, useMemo, useRef } from 'react';
import {
  CaseMinimalisticIcon,
  IconFaceScan,
  IconMyCompany,
  IconMyService,
  IconService,
  IconSetting,
  IconSupportPhone,
  IconUser,
  LandingIcon,
  RequestQuotationIcon,
} from '../components/icons';
import {
  MenuType,
  PageLayoutLeftSideTab,
} from '../layouts/PageLayoutLeftSideTab';
import { KycContent } from './KycContent';
import { MyAccountContent } from './MyAccountContent';
import { OrderPaymentContent } from './OrderPaymentContent';
import { ServicesContent } from './ServicesContent';
import { UsersContent } from './UsersContent';
import { MasterServiceContent } from './MasterServicesContent';
import { useLocation } from 'react-router-dom';
import { SupportContent } from './SupportContent';
import { AuthContext } from '../contexts/AuthContextProvider';
import CompanyManagement from './CompanyManagement';
import RequestManagement from './RequestManagement';
import QuotationRequestContent from './QuotationRequestContent';
import LandingManagement from './LandingManagement';

export type HomeContent =
  | 'services'
  | 'orderPayment'
  | 'KYCRequest'
  | 'support'
  | 'user'
  | 'companyManagement'
  | 'configuration'
  | 'requestManagement'
  | 'quotation'
  | 'landing'
const TabOptionGroup: Record<HomeContent, MenuType> = {
  services: {
    iconElement: <IconService />,
    id: '/services',
    path: 'services',
    label: 'Services',
  },
  quotation: {
    iconElement: <RequestQuotationIcon className='h-5 w-5' />,
    id: '/quotation',
    path: 'quotation',
    label: 'Quotation Request',
  },
  orderPayment: {
    id: '/orderPayment',
    path: 'orderPayment',
    iconElement: <IconMyService />,
    label: 'Order Payment',
  },
  KYCRequest: {
    id: '/KYCRequest',
    path: 'KYCRequest',
    iconElement: <IconFaceScan />,
    label: 'KYC Request',
  },
  user: {
    id: '/user',
    path: 'user',
    iconElement: <IconUser />,
    label: 'User',
  },
  companyManagement: {
    id: '/companyManagement',
    path: 'companyManagement',
    iconElement: <IconMyCompany />,
    label: 'Company Management',
  },
  requestManagement: {
    id: 'requestManagement',
    iconElement: <CaseMinimalisticIcon />,
    label: 'Request Management',
    items: [
      {
        id: '/requestManagement/transfer',
        path: 'requestManagement/transfer',
        iconElement: <IconMyService />,
        label: 'Transfer Services',
      },
      {
        id: '/requestManagement/refer',
        path: 'requestManagement/refer',
        iconElement: <IconMyService />,
        label: 'Refer Services',
      },
      {
        id: '/requestManagement/addOn',
        path: 'requestManagement/addOn',
        iconElement: <IconMyService />,
        label: 'Add-on Services',
      },
      {
        id: '/requestManagement/core',
        path: 'requestManagement/core',
        iconElement: <IconMyService />,
        label: 'Core Services',
      },
    ],
  },
  landing: {
    id: '/landingContent',
    path: 'landingContent',
    iconElement: <LandingIcon />,
    label: 'Landing Page Management',
  },
  support: {
    id: '/support',
    path: 'support',
    iconElement: <IconSupportPhone />,
    label: 'Support',
  },
  configuration: {
    id: '/configuration',
    path: 'configuration',
    iconElement: <IconSetting />,
    label: 'Configuration',
  },
};

export function HomePage() {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const isAdmin = user?.roles?.[0]?.name === 'ROLE_ADMIN';
  const homeContent = location.pathname === '/' ? '/services' : location.pathname;
  const tabOptions = useMemo(() => {
    let result = Object.values(TabOptionGroup);
    if (!isAdmin)
      result = result.filter((item) => item.id !== '/configuration');
    return result;
  }, [user]);

  return (
    <div className="w-screen h-screen bg-cover flex flex-col overflow-hidden">
      <PageLayoutLeftSideTab tabOptions={tabOptions}>
        <div className={'w-full h-full flex flex-col overflow-x-hidden'}>
          <div
            className={
              'w-full flex grow relative overflow-y-scroll overflow-x-hidden'
            }
          >
            {homeContent === TabOptionGroup.KYCRequest.id && (
              <KycContent key={TabOptionGroup.KYCRequest.id} />
            )}
            {homeContent === TabOptionGroup.quotation.id && (
              <QuotationRequestContent key={TabOptionGroup.quotation.id} />
            )}
            {homeContent === TabOptionGroup.orderPayment.id && (
              <OrderPaymentContent key={TabOptionGroup.user.id} />
            )}
            {homeContent === TabOptionGroup.services.id && (
              <ServicesContent key={TabOptionGroup.services.id} />
            )}
            {homeContent === TabOptionGroup.user.id && (
              <UsersContent key={TabOptionGroup.user.id} />
            )}
            {homeContent === TabOptionGroup.companyManagement.id && (
              <CompanyManagement key={TabOptionGroup.companyManagement.id} />
            )}
            {TabOptionGroup.requestManagement?.items?.some(
              (item) => item.id === homeContent,
            ) && (
              <RequestManagement
                key={homeContent}
                path={homeContent}
              />
            )}
            {homeContent === TabOptionGroup.configuration.id && isAdmin && (
              <MasterServiceContent key={TabOptionGroup.configuration.id} />
            )}
            {homeContent === TabOptionGroup.support.id && (
              <SupportContent key={TabOptionGroup.support.id} />
            )}
            {homeContent === TabOptionGroup.landing.id && (
              <LandingManagement key={TabOptionGroup.landing.id} />
            )}
            {homeContent === '/myAccount' && (
              <MyAccountContent key="myAccount" />
            )}
          </div>
        </div>
      </PageLayoutLeftSideTab>
    </div>
  );
}
