import { UseQueryResult } from '@tanstack/react-query';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UploadedDocumentType } from '../api/types';
import { getFile } from '../api/upload';
import { Status, StatusBackgroundClassNameMap, StatusTextClassNameMap } from '../constants/StatusBadge';
import { NONE_REQUIRED } from '../constants/global';
import {
  useApiSendPaymentReminder,
  useApiSendRequiredDocumentReminder,
  useApiServiceUpdateAdminRemark,
  useApiServiceUploadServiceResult,
  useApiServiceUploadStatusStep,
} from '../hooks-api/useServiceApi';
import { useValidateCaller } from '../hooks-ui/useValidateCaller';
import { ApiFileWrap, Service, ServiceStep } from '../types/service';
import { FormFieldSelect } from './FormFieldSelect';
import InputFile from './InputFile';
import { IconAltArrowDown } from './icons';
import { v4 as uuidv4 } from 'uuid';
import { Menu, Transition } from '@headlessui/react';
import { cn } from '../utils/cn.util';
import ButtonCs from './ButtonCs';
import { SERVICE_STEP_STATUS } from './ServiceDetailDialog';
import ConfirmDialog from './ConfirmDialog';
import { DialogContainer } from './DialogContainer';
import { SinglePagePDF } from './SinglePagePDF';
import { getRandomColor } from '../helpers/helpers';
import { callApiRequestSign } from '../api/serviceManagement';

type Props = {
  serviceStep: ServiceStep | null;
  resGetServiceId?: UseQueryResult<Service, Error>;
  service: Service | null;
};

export interface IUser {
  id: string;
  color: string;
  email: string;
  name: string;
  access_code: string;
  element: {
    id: string;
    page: number;
    position: string;
    isActive: boolean;
  }[];
}

type FileResult = ApiFileWrap &
  Partial<{ fileData: File; status: string | null }>;
export function ServiceStepContent({
  serviceStep,
  resGetServiceId,
  service,
}: Props) {
  const DEFAULT_POSITION = {
    id: uuidv4(),
    page: 0,
    position: '',
    isActive: false,
  };
  const DEFAULT_SIGNER = {
    id: uuidv4(),
    color: getRandomColor(),
    email: '',
    name: '',
    access_code: '',
    isActive: false,
    element: [DEFAULT_POSITION],
  };
  const { t } = useTranslation();

  const [fileResults, setFileResults] = useState<FileResult[]>([]);
  const [adminRemark, setAdminRemark] = useState('');
  const [statusStep, setStatusStep] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<{ [key in number]: boolean }>({});
  const [isPDF, setIsPDF] = useState<{ [key in number]: boolean }>({});
  const [signConfig, setSignConfig] = useState<{
    lock_order: boolean;
    users: IUser[];
  }>({
    lock_order: false,
    users: [DEFAULT_SIGNER],
  });
  const [urlContract, setUrlContract] = useState<{
    id: number;
    path: string;
    fileId: string;
  }>({
    id: 0,
    path: '',
    fileId: '',
  });

  const { validateCaller, validateAll } = useValidateCaller();
  const currentStep = useRef<string>('');
  const isPDFRef = useRef<{ [key in number]: boolean }>(isPDF);

  useEffect(() => {
    setAdminRemark(serviceStep?.adminRemark ?? '');
    setStatusStep(serviceStep?.statusStep ?? '');
    setFileResults(serviceStep?.result || []);
  }, [serviceStep, service]);

  const mutateUpdateAdminRemark = useApiServiceUpdateAdminRemark();
  const mutateUploadStatusStep = useApiServiceUploadStatusStep();

  const updateAdminRemark = () => {
    if (serviceStep) {
      mutateUpdateAdminRemark.mutate(
        {
          id: serviceStep?.id,
          adminRemark,
        },
        {
          onSuccess: (data) => {
            toast.success(t('Update status remark successfully'));
          },
          onError: (error) => {
            toast.error(String(error));
          },
          onSettled: () => {
            resGetServiceId?.refetch();
          },
        },
      );
    }
  };

  const uploadStatusStep = (status: Status) => {
    if (serviceStep) {
      mutateUploadStatusStep.mutate(
        {
          id: serviceStep?.id,
          status,
        },
        {
          onSuccess: (data) => {
            toast.success(t('Update status step successfully'));
          },
          onError: (error) => {
            // toast.error(t('Update status step failed'));
            toast.error(String(error));
          },
          onSettled: () => {
            resGetServiceId?.refetch();
          },
        },
      );
    }
  };

  const onDownloadServiceUpload = (item: UploadedDocumentType) => {
    try {
      if (item.fileDocument) {
        getFile(item.fileDocument);
      }
    } catch (error) {
      toast.error(String(error));
      console.error('error: ', error);
    }
  };

  const mutateUploadFile = useApiServiceUploadServiceResult();

  const handleChangeFile = async (fileData: File | undefined, id: number) => {
    const newFileResults = [...fileResults];
    const fileChanged = newFileResults.find(
      (fileResult) => fileResult.id === id,
    );
    if (!serviceStep || !fileChanged) {
      return;
    }
    if (fileData) {
      const formData = new FormData();
      formData.append('files', fileData);
      formData.append('id', id.toString());
      try {
        const res = await mutateUploadFile.mutateAsync(formData);
        fileChanged.fileDocument = res.data?.[0] || null;
        toast.success(t('Update file successfully'));
        resGetServiceId?.refetch();
      } catch (error) {
        toast.error(String(error));
        console.error('error: ', error);
      }
    } else {
      fileChanged.fileDocument = null;
    }
    fileChanged.fileData = fileData;
    setFileResults(newFileResults);
  };

  const handleMenuItemClick = (option: any) => {
    option.click && option.click();
  };

  const mutateSendPaymentReminder = useApiSendPaymentReminder();
  const mutateSendRequiredDocumentReminder =
    useApiSendRequiredDocumentReminder();

  const handleSendPaymentReminder = () => {
    mutateSendPaymentReminder.mutate(
      {
        email: service?.email,
      },
      {
        onSuccess: (data) => {
          toast.success(t('Send Payment Reminder successfully'));
        },
        onError: (error) => {
          toast.error(String(error));
        },
      },
    );
    setTimeout(() => setIsOpen(false), 500);
  };

  const handleSendRequiredDocumentReminder = () => {
    const listDocArr = serviceStep?.customerDocument.map(
      (doc) => doc.requiredDocument,
    );
    mutateSendRequiredDocumentReminder.mutate(
      {
        email: service?.email,
        listDoc: listDocArr,
      },
      {
        onSuccess: (data) => {
          toast.success(t('Send Required Document Reminder successfully'));
        },
        onError: (error) => {
          toast.error(String(error));
        },
      },
    );
    setTimeout(() => setIsOpen(false), 500);
  };

  const handleSendRequestSign = async () => {
    if (!serviceStep?.id) return;
    setIsLoading({ ...isLoading, [urlContract.id]: true });
    try {
      const body = {
        lock_order: signConfig.lock_order,
        signers: signConfig.users.map((user) => {
          return {
            name: user.name,
            email: user.email,
            access_code: user.access_code,
            files: [
              {
                fileId: urlContract.fileId,
                elements: user.element.map((el) => ({
                  position: el.position,
                  pages: el.page,
                })),
              },
            ],
          };
        }),
      };
      setUrlContract({
        id: 0,
        path: '',
        fileId: '',
      });
      await callApiRequestSign(urlContract.id, body);
    } catch {
    } finally {
      setIsLoading({ ...isLoading, [urlContract.id]: false });
      setSignConfig({
        lock_order: false,
        users: [DEFAULT_SIGNER],
      });
      resGetServiceId?.refetch();
    }
  };

  type OptionType = {
    label?: string;
    click?: () => void;
    disabled?: boolean;
    loading?: boolean;
  };

  const disabledDocumentReminder = serviceStep?.customerDocument.every(
    (item) => item.fileDocument,
  );

  const OPTIONS: OptionType[] = [
    {
      label: t('Send Payment Reminder'),
      click: () => {
        currentStep.current = t('Send Payment Reminder');
        setIsOpen(true);
      },
      disabled: service?.statusPayment === Status.APPROVED,
      loading: mutateSendPaymentReminder.status === 'pending',
    },
    {
      label: t('Send Required Document Reminder'),
      click: () => {
        currentStep.current = t('Send Required Document Reminder');
        setIsOpen(true);
      },
      disabled: disabledDocumentReminder,
      loading: mutateSendRequiredDocumentReminder.status === 'pending',
    },
  ];

  return (
    <div className={'w-full border border-primary_25 rounded-xl py-lg px-xl'}>
      <div className={'flex gap-4 mb-4 items-center '}>
        <div className={'font-bold text-xl w-full lg:w-[60%] mr-auto'}>
          {serviceStep?.stepName}
        </div>
        <div className="flex gap-4 items-center">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary/90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                {t('Send Reminder')}
                <IconAltArrowDown
                  className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="px-1 py-1 ">
                  {OPTIONS.map((option, index) => (
                    <Menu.Item key={index}>
                      {({ active }) =>
                        option.disabled ? (
                          <ButtonCs
                            className={`w-full justify-start rounded-none bg-transparent text-primary opacity-50 cursor-not-allowed`}
                          >
                            {option.label}
                          </ButtonCs>
                        ) : (
                          <ButtonCs
                            onClick={() =>
                              !option?.loading && handleMenuItemClick(option)
                            }
                            isLoading={option?.loading}
                            className={cn(
                              `w-full justify-start rounded-none`,
                              active
                                ? 'bg-primary'
                                : 'bg-transparent text-primary',
                              option?.loading
                                ? 'opacity-50 cursor-not-allowed'
                                : '',
                            )}
                          >
                            {option.label}
                          </ButtonCs>
                        )
                      }
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <FormFieldSelect
            id={'serviceStepStatus'}
            isFixedValue={
              statusStep === Status.ISSUED ||
              service?.statusService === Status.PENDING
            }
            onChange={uploadStatusStep}
            validateCaller={validateCaller}
            optionInfos={SERVICE_STEP_STATUS}
            value={statusStep as Status}
          />
        </div>
      </div>
      <div className={'mb-4'}>
        <div className={'flex gap-4 mb-2 items-center'}>
          <span className={'text-lg font-bold'}>{t("Admin's remark")}</span>
          <button
            className="w-[100px] h-[35px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg py-2"
            onClick={updateAdminRemark}
          >
            {t('Save')}
          </button>
        </div>
        <textarea
          className={'w-full p-2 border rounded-xl border-gray-300'}
          maxLength={500}
          value={adminRemark}
          onChange={(e) => setAdminRemark(e.target.value)}
        ></textarea>
      </div>
      <div className="mt-rootRootPadding">
        <div className="text-base font-bold leading-6">
          {t('Customer document')}
        </div>
        <div className="border rounded-md border-primary_25 mt-md">
          <div className="border-b  border-primary_25 grid grid-cols-1 lg:grid-cols-2 gap-md items-center py-md">
            <div className="px-md text-left lg:text-center">
              {t('Required document')}
            </div>
            <div className="px-md text-left lg:text-center">
              {t('Uploaded document')}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-md items-center py-md">
            {serviceStep?.customerDocument.map((item, index) => (
              <Fragment key={`customerDocument${item.id}`}>
                <div className="px-md text-[#3B3F48]/85 text-left lg:text-center">
                  {index + 1}. {item.requiredDocument}
                </div>
                <div
                  className={`px-md flex justify-start items-center text-left lg:text-center gap-2 ${
                    fileResults?.find((i) => i.id === item.id)?.fileDocument
                      ? 'justify-start'
                      : 'justify-center'
                  } `}
                >
                  <InputFile
                    key={`file${item.id}`}
                    label={t('Upload')}
                    onChange={(file) => handleChangeFile(file, item.id)}
                    fileName={item.fileDocument ?? undefined}
                    maxSize={10}
                    canDeleteFile={false}
                    accept="*"
                    disabled={false}
                  />
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-rootRootPadding">
        <div className="text-base font-bold leading-6">
          {t('Service Result')}
        </div>
        <div className="border rounded-md border-primary_25 mt-md">
          <div className="border-b  border-primary_25 grid grid-cols-1 lg:grid-cols-2 gap-md items-center py-md">
            <div className="px-md text-left lg:text-center">
              {t('Required document')}
            </div>
            <div className="px-md text-left lg:text-center">
              {t('Uploaded document')}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-md items-center py-md">
            {fileResults.map((fileResult, index) => (
              <Fragment key={`customerDocument${fileResult.id}`}>
                <div className="px-md text-[#3B3F48]/85 text-left lg:text-center">
                  {index + 1}. {fileResult.requiredDocument}
                </div>
                <div
                  className={`flex justify-start items-center text-left lg:text-center gap-2 mr-2 ${
                    fileResults?.find((i) => i.id === fileResult.id)
                      ?.fileDocument
                      ? 'justify-start'
                      : 'justify-center'
                  } `}
                >
                  <InputFile
                    key={`file${fileResult.id}`}
                    label={t('Upload')}
                    onChange={(file) => handleChangeFile(file, fileResult.id)}
                    fileName={
                      fileResults?.find((i) => i.id === fileResult.id)
                        ?.fileDocument || undefined
                    }
                    maxSize={10}
                    setIsPDF={(value) => {
                      isPDFRef.current = {
                        ...isPDFRef.current,
                        [fileResult.id]: value,
                      };
                      setIsPDF(isPDFRef.current);
                    }}
                    accept="*"
                    disabled={fileResult.requiredDocument === NONE_REQUIRED}
                  />
                  {fileResult.fileDocument &&
                    isPDFRef.current[fileResult.id] && (
                      <ButtonCs
                        type="submit"
                        isLoading={isLoading[fileResult.id]}
                        onClick={async () => {
                          if (!fileResult.fileDocument) return;
                          const blob = await getFile(
                            fileResult.fileDocument.toString(),
                            { download: false },
                          );
                          blob &&
                            setUrlContract({
                              path: URL.createObjectURL(blob),
                              id: fileResult.id,
                              fileId: fileResult.fileDocument,
                            });
                        }}
                        disabled={Boolean(fileResult.status)}
                        className={`${fileResult.status ? `${StatusBackgroundClassNameMap[fileResult.status]} ${StatusTextClassNameMap[fileResult.status]}` : ''}`}
                      >
                        {fileResult.status ?? t('Sign')}
                      </ButtonCs>
                    )}
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      {urlContract.path && (
        <DialogContainer
          onClose={() => {
            setUrlContract({
              id: 0,
              path: '',
              fileId: '',
            });
          }}
          isCloseOnClickOverlay
          isFullSize
          isAutoSize
          panelClassName={
            'max-w-[1400px] min-h-[60vh] max-h-[95vh] overflow-y-auto'
          }
        >
          <SinglePagePDF
            path={urlContract.path}
            signConfig={signConfig}
            setSignConfig={setSignConfig}
            onOk={handleSendRequestSign}
            onClose={() => {
              setUrlContract({
                id: 0,
                path: '',
                fileId: '',
              });
              setSignConfig({
                lock_order: false,
                users: [DEFAULT_SIGNER],
              });
            }}
          />
        </DialogContainer>
      )}
      <ConfirmDialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          currentStep.current = '';
        }}
        onAccept={() =>
          currentStep.current === t('Send Payment Reminder')
            ? handleSendPaymentReminder()
            : handleSendRequiredDocumentReminder()
        }
        title="Confirm Infomation"
        contentMsg={`Are you sure you want to ${currentStep.current?.toLowerCase()}?`}
      />
    </div>
  );
}
