import { UseMutationOptions, useMutation, useQuery } from "@tanstack/react-query"
import { callApiApproveOrder, callApiGetOrders, callApiRejectOrder } from "../../api/payment"
import { ApiGetOrdersParam } from "../../api/types";
import { KeyFactory } from "../../services-base/key-factory"

export const useApiGetOrders = (params: ApiGetOrdersParam) => {
    return useQuery({
      queryKey: KeyFactory.getOrders(params.page),
      queryFn: () => callApiGetOrders(params)
    })
}

export const useApiApproveOrder = ({onError, onSuccess}: Pick<UseMutationOptions<unknown, Error, string | number, unknown>, 'onError' | 'onSuccess'> = {}) => {
  return useMutation({
    mutationFn: callApiApproveOrder,
    onError,
    onSuccess
  })
}

export const useApiRejectOrder = ({onError, onSuccess}: Pick<UseMutationOptions<unknown, Error, string | number, unknown>, 'onError' | 'onSuccess'> = {}) => {
  return useMutation({
    mutationFn: callApiRejectOrder,
    onError,
    onSuccess
  })
}
