import { useState } from 'react';
import { FormFieldText } from '../components/FormFieldText';
import { useTranslation } from 'react-i18next';
import ButtonCs from '../components/ButtonCs';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { FormFieldSelect } from '../components/FormFieldSelect';
import { NATION_INFOS } from '../constants/SelectionOptions';
import { IconPen } from '../components/icons';
import { useApiGetCompany } from '../hooks/api/company';
import { DialogContainer } from '../components/DialogContainer';
import { MyCompanyDetailPage } from '../components/service/my-company/MyCompanyDetailPage';
import { generateFormatDate } from '../services-ui/date';

const DEFAULT_SEARCH = {
  companyName: '',
  customerEmail: '',
  serviceNation: '',
};

const CompanyManagement = () => {
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });
  const [showCompanyDetailDialog, setShowCompanyDetailDialog] = useState(false);
  const [idSelected, setIdSelected] = useState<number>(0);
  const [nation, setNation] = useState<string>('');

  const [dataSearch, setDataSearch] = useState<{
    [key in string]: string;
  }>(DEFAULT_SEARCH);

  const { data, refetch } = useApiGetCompany({
    page: paginationModel.page,
    size: paginationModel.pageSize,
    companyName: dataSearch.companyName,
    customerEmail: dataSearch.customerEmail,
    serviceNation: dataSearch.serviceNation,
  });

  const comManagementColumn: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'No.',
      width: 50,
      valueGetter: (params) =>
        params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
    },
    {
      field: 'companyName',
      headerName: 'Company name',
      width: 180,
      sortable: true,
    },
    {
      field: 'customerEmail',
      headerName: 'Customer email',
      sortable: true,
      width: 200,
    },
    {
      field: 'companyType',
      headerName: 'Company type',
      sortable: true,
      type: 'string',
      width: 150,
    },
    {
      field: 'llcInNation',
      headerName: 'Service nation',
      sortable: true,
      width: 150,
    },
    {
      field: 'industry',
      headerName: 'Industry',
      sortable: true,
      type: 'string',
      width: 200,
    },
    {
      field: 'formationDate',
      headerName: 'Formation Date',
      sortable: true,
      type: 'string',
      width: 200,
      valueGetter: (params) => {
        return params.row.formationDate
          ? generateFormatDate(new Date(params.row.formationDate))
          : '';
      },
    },

    {
      field: 'updatedBy',
      headerName: 'Updated By',
      sortable: true,
      type: 'string',
      width: 200,
      valueGetter: (params: GridValueGetterParams<any>) => {
        return params.row.updatedBy;
      },
    },
    {
      field: 'Option',
      headerName: 'Option',
      sortable: false,
      type: 'string',
      width: 150,
      renderCell: (row) => (
        <button
          className=" rounded bg-primary px-10 py-1 svg-white"
          onClick={() => {
            setShowCompanyDetailDialog(true);
            setIdSelected(row.row.id);
            setNation(row.row.llcInNation);
          }}
        >
          <IconPen className="fill-white" />
        </button>
      ),
    },
  ];

  const handleReset = () => {
    setDataSearch(DEFAULT_SEARCH);
    setTimeout(refetch);
  };

  const handleSearch = () => {
    refetch();
  };
  return (
    <div className={'w-full grow flex flex-col p-3'}>
      <div
        className={
          'flex flex-col grow overflow-auto bg-white rounded justify-start items-center py-6 px-4 sm:px-8'
        }
      >
        <div className={'text-h4 w-full text-start mb-6'}>
          {t('Company management')}
        </div>
        <div
          className={'w-full grow'}
          key={data?.content?.map((value: any) => value.id).join('_')}
        >
          <div
            className={
              'w-full flex flex-row justify-between items-center gap-10 mb-4'
            }
          >
            <div
              className={
                'w-full flex flex-row justify-start items-end gap-4 mb-4'
              }
            >
              <FormFieldText
                label={t('Company name')}
                id="companyName"
                validateCaller={{}}
                onChange={(e) =>
                  setDataSearch({ ...dataSearch, companyName: e })
                }
                value={dataSearch.companyName}
                placeholder={t('Input company name')}
              />
              <FormFieldText
                label={t('Customer email')}
                id="customerEmail"
                validateCaller={{}}
                onChange={(e) =>
                  setDataSearch({ ...dataSearch, customerEmail: e })
                }
                value={dataSearch.customerEmail}
                placeholder={t('Input customer email')}
              />
              <div className="w-[200px]">
                <FormFieldSelect
                  optionInfos={NATION_INFOS}
                  label={t('Service nation')}
                  className="w-full"
                  id="serviceNation"
                  value={dataSearch.serviceNation}
                  placeholder={t('Select Nation')}
                  validateCaller={{}}
                  onChange={(value) =>
                    setDataSearch({ ...dataSearch, serviceNation: value })
                  }
                />
              </div>
              <div className="flex gap-2">
                <ButtonCs
                  type="button"
                  className="bg-transparent border border-surface text-primary"
                  onClick={handleReset}
                >
                  {t('Reset')}
                </ButtonCs>
                <ButtonCs type="submit" onClick={handleSearch}>
                  {t('Apply')}
                </ButtonCs>
              </div>
            </div>
          </div>
          <DataGrid
            paginationMode="server"
            rows={data?.content || []}
            columns={comManagementColumn}
            sx={{
              height: 'calc(100vh - 250px)',
            }}
            pageSizeOptions={[25]}
            rowCount={data?.totalElements || 0}
            getRowId={(row) => row.id}
            paginationModel={paginationModel}
            onPaginationModelChange={(model) => setPaginationModel(model)}
          />
        </div>
      </div>
      {showCompanyDetailDialog && (
        <DialogContainer
          onClose={() => {
            setShowCompanyDetailDialog(false);
          }}
          isCloseOnClickOverlay
          isFullSize
          isAutoSize
          panelClassName={'max-w-[1400px] min-h-[70vh]'}
        >
          <MyCompanyDetailPage
            id={idSelected}
            nation={nation}
            needUpdate={true}
            isCancel={() => setShowCompanyDetailDialog(false)}
            reloadList={refetch}
          />
        </DialogContainer>
      )}
    </div>
  );
};

export default CompanyManagement;
