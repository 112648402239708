import { Fragment, JSX, PropsWithChildren, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { callApiLogout } from "../api/account";
import logo_full from "../assets/images/logo_full.png";
import { I18nLanguage } from "../components/I18nLanguage";
import { IconAccountCircle, IconArrowRight, IconLogout, IconX } from "../components/icons";
import { AuthContext } from "../contexts/AuthContextProvider";
import { RoutePaths } from "../pages/router";
import { removeAuthInfo } from "../services-business/api/authentication";
import { Menu } from "@headlessui/react";
import { cn } from "../utils/cn.util";

export type TabOption<TabId> = {
  iconElement: JSX.Element,
  id: TabId,
  label: string
}

export type MenuType = {
  id: string;
  iconElement: JSX.Element;
  path?: string;
  label: string;
  items?: MenuType[];
  serviceId?: number;
};

type Props<T> = PropsWithChildren<{
  tabOptions: MenuType[],
}>
export function PageLayoutLeftSideTab<T extends string>(props: Props<T>) {
  const translation = useTranslation()
  const [isOpenOnSmallScreen, setIsOpenOnSmallScreen] = useState<boolean>(false)
  const navigate = useNavigate()
  const {user, removeAuthUser} = useContext(AuthContext)

  function handleClickLogout() {
    navigate(RoutePaths.login)
    callApiLogout().catch(e => console.error(e))
    removeAuthInfo()
    removeAuthUser()
  }

  function handleClickAccount() {
    // @ts-ignore
    navigate('myAccount')
  }

  return <div className={"w-full h-full bg-surface flex flex-row"}>
    {isOpenOnSmallScreen && <div className={"lg:none bg-black absolute z-50 top-0 left-0 w-screen h-screen opacity-40"}></div>}
    <div className={"absolute top-0 left-0 z-50 lg:shrink-0 lg:flex lg:relative lg:w-full max-w-[300px] min-h-screen h-full bg-white flex-col justify-between " + (isOpenOnSmallScreen ? "w-full flex" : "w-0 hidden")}>
      <div className={"grow"}>
        <div
          className={"flex flex-row items-center mx-4 mt-6 justify-between lg:justify-start"}>
          <img className="w-[150px] cursor-pointer" src={logo_full} alt="logo_full"/>
          <div className={"block lg:hidden p-2 bg-gray-100 rounded-full cursor-pointer"}>
            <IconX onClick={setIsOpenOnSmallScreen.bind(undefined, false)} />
          </div>
        </div>
        <div className={"mt-10"}>
          <p className={"ml-4 h-10 uppercase font-bold"}>{translation.t('OVERVIEW')}</p>
          {props.tabOptions.filter(tab => tab.id !== 'myAccount').map(tabOption => (
                <Fragment key={tabOption.id}>
                  <Menu as="div" className="relative">
                    <div className="relative group " key={tabOption.id}>
                      <TabOption {...tabOption} />
                      {tabOption.items && (
                        <>
                          <Menu.Items
                            className={`
                      absolute w-[260px] -right-[250px] top-[50px] lg:top-0 mt-2 bg-white border border-gray-200 rounded shadow-lg z-10 max-h-[400px] overflow-auto
                      `}
                            style={{
                              height: tabOption.items.length * 65 + "px",
                            }}
                          >
                            <div className="flex flex-col">
                              {tabOption.items?.map((itemChildren) => (
                                <TabOptionItem
                                  key={itemChildren.id}
                                  {...itemChildren}
                                />
                              ))}
                            </div>
                          </Menu.Items>
                        </>
                      )}
                    </div>
                  </Menu>
                </Fragment>
              ))}

        </div>
      </div>
      <div className={"w-full p-4"}>
        <I18nLanguage />
        <div className={"flex flex-row justify-between items-center mt-4"}>
          <div className={"flex flex-row items-center gap-2 cursor-pointer"} onClick={handleClickAccount}>
            <IconAccountCircle className={"w-10 h-10"} />
            <span className={"font-bold"}>{user?.lastName}</span>
          </div>
          <IconLogout className={"h-6 w-6 cursor-pointer"} onClick={handleClickLogout} />
        </div>
      </div>
    </div>
    {props.children}
  </div>
}

function TabOption(props: MenuType) {
  const translation = useTranslation();
  return (
    <>
      {Boolean(props.items) ? (
        <>
          <Menu.Button className={"h-[50px] w-full px-4 py-2"}>
            <div
              className={cn(
                "w-full h-full flex flex-row gap-3 px-3 items-center rounded-md cursor-pointer hover:bg-gray-300 "
              )}
            >
              <div className={"hidden lg:block"}>{props.iconElement}</div>
              <span>{translation.t(props.label)}</span>
            </div>
            <div className="absolute top-1/2 -translate-y-2/4 right-5">
              <IconArrowRight />
            </div>
          </Menu.Button>
        </>
      ) : (
        <>
          <Menu.Button className={"h-[50px] px-4 py-2 w-[300px] "}>
            <NavLink
              to={props.path as string}
              className={({ isActive }) =>
                cn(
                  "w-full h-full flex flex-row gap-3 px-3 items-center rounded-md cursor-pointer hover:bg-gray-300 ",
                  {
                    "bg-gray-300": isActive,
                  }
                )
              }
            >
              <div className={"hidden lg:block"}>{props.iconElement}</div>
              <span>{translation.t(props.label)}</span>
            </NavLink>
          </Menu.Button>
        </>
      )}
    </>
  );
}

function TabOptionItem(props: MenuType) {
  const translation = useTranslation();
  return (
    <Menu.Item key={`menu${props.id}`}>
      {({ active, close }) => (
        <Menu.Button className={"h-full p-2 inline-block"}>
          <NavLink
            to={props.path as string}
            onClick={close}
            className={({ isActive }) =>
              cn(
                "w-full h-full flex flex-row gap-3 p-3 items-center rounded-md cursor-pointer hover:bg-gray-300 ",
                {
                  "bg-gray-300": isActive || active,
                }
              )
            }
          >
            <div className={"hidden lg:block"}>{props.iconElement}</div>
            <span
              className="line-clamp-1 text-start"
              title={translation.t(props.label)}
            >
              {translation.t(props.label)}
            </span>
          </NavLink>
        </Menu.Button>
      )}
    </Menu.Item>
  );
}
