import { useTranslation } from 'react-i18next';
import { RequestManagementResponese, ViewedUser } from '../api/types';
import { IconX } from './icons';
import { FormFieldSelect } from './FormFieldSelect';
import { FormFieldText } from './FormFieldText';
import { FormFieldMultiSelect } from './FormFieldMultiSelect';
import { FormFieldTextArea } from './FormFieldArea';
import { INDUSTRY_INFOS, NATION_INFOS } from '../constants/SelectionOptions';
import { OptionInfo } from '../types/common';
import { useApiUserSearchByRole } from '../hooks/api/user';
import { useContext, useMemo } from 'react';
import { SERVICE_STEP_STATUS } from './ServiceDetailDialog';
import { Status } from '../constants/StatusBadge';
import { GridColDef } from '@mui/x-data-grid';
import { callApiUpdateRequest } from '../api/requestManagement';
import { AuthContext } from '../contexts/AuthContextProvider';
import FormDateTime from './FormDateTime';
import dayjs from 'dayjs';

interface IProps {
  data: RequestManagementResponese;
  refetch: () => void;
  onClose: () => void;
  dataConfig: {
    id: number;
    path: string;
    header: GridColDef[];
    titlePopup: string;
    title: string;
    pathUpdate: string;
  };
}

const DialogRequestDetail = (props: IProps) => {
  const { data, refetch, dataConfig, onClose } = props;
  const { t } = useTranslation();
  const resUserByRole = useApiUserSearchByRole({ role: 'mod' });
  const { user } = useContext(AuthContext);
  const isAdmin = user?.roles?.[0]?.name === 'ROLE_ADMIN';
  const listUserPIC: ViewedUser[] = resUserByRole.data || [];
  const PIC_OPTION = useMemo(() => {
    return listUserPIC.map((item) => {
      return { value: item.email, label: item.email };
    });
  }, [listUserPIC]);

  const handleUpdateInfo = async (
    value: string,
    field: 'status' | 'pic' | 'id',
  ) => {
    const bodyTemp = {
      status: data.status ?? Status.PENDING,
      pic: data.pic ?? '',
      id: data.id ?? 0,
    };
    if (field !== 'id') bodyTemp[field] = value;
    await callApiUpdateRequest(bodyTemp, dataConfig.pathUpdate);
    data.pic = bodyTemp.pic;
    data.status = bodyTemp.status;
    refetch();
  };

  const labelEntity = useMemo(() => {
    switch (data.countryCompanyIncorporated) {
      case 'United States':
        return {
          label: 'Employer Identification Number (EIN)',
          placeHolder: 'Enter your EIN',
        };
      case 'Hong Kong':
        return {
          label: 'Business Registration Number (BRN)',
          placeHolder: 'Enter your BRN',
        };
      case 'Singapore':
        return {
          label: 'Unique Entity Number (UEN)',
          placeHolder: 'Enter your UEN',
        };

      default:
        return {
          label: 'Unique Entity Number (UEN)',
          placeHolder: 'Enter your UEN',
        };
    }
  }, [data.countryCompanyIncorporated]);

  const handleRenderBody = () => {
    switch (dataConfig.id) {
      case 1:
        return (
          <>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your name')}
                placeholder="Enter your name"
                isRequired
                className="w-full"
                id="customerName"
                value={data.customerName}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your email')}
                placeholder="Enter your email"
                isRequired
                className="w-full"
                id="email"
                value={data.email}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your phone')}
                placeholder="Enter your phone"
                isRequired
                className="w-full"
                id="phone"
                value={data.phone}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldSelect
                isFixedValue={true}
                optionInfos={INDUSTRY_INFOS}
                placeholder="Select a industry"
                isRequired
                label={t('Industry')}
                className="w-full"
                id="industry"
                value={data.industry}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Company name')}
                placeholder="Enter your company name"
                isRequired
                className="w-full"
                id="companyName"
                value={data.companyName}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Current Service-provider')}
                placeholder="Enter your current Service-provider"
                isRequired
                className="w-full"
                id="currentServiceProvider"
                value={data.currentServiceProvider}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldSelect
                isFixedValue={true}
                optionInfos={NATION_INFOS}
                placeholder="Select a nation"
                isRequired
                label={t('Country that the company incorporated')}
                className="w-full"
                id="countryCompanyIncorporated"
                value={data.countryCompanyIncorporated}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div>
              <FormDateTime
                label={t('Incorporation Date')}
                value={
                  data?.incorporationDate
                    ? dayjs(data?.incorporationDate)
                    : null
                }
                isRequired
                readOnly
                onChange={(value) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={labelEntity.label}
                placeholder={labelEntity.placeHolder}
                isRequired
                className="w-full"
                id="uniqueEntityNumber"
                value={data.uniqueEntityNumber}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-3">
              <FormFieldTextArea
                isFixedValue={true}
                label={t('Remark')}
                className="w-full"
                placeholder="Anything else we should know?"
                id="Remark"
                value={data.description}
                row={4}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="col-span-1">
              <p className="flex text-cBase font-bold gap-1">
                <span>{t('Referral type')}</span>
                <span className="text-red-600">*</span>
              </p>
              <div className="flex justify-end gap-4 rounded-lg border-[1px] p-2 mt-2 h-10">
                <label
                  htmlFor={`individual-check`}
                  className="flex gap-1 items-center"
                >
                  <input
                    type="checkbox"
                    name="type"
                    id={`individual-check`}
                    className="accent-[#094752] w-5 h-5 border-surface rounded-lg"
                    checked={data.type === 1}
                    onChange={(event) => {}}
                    disabled
                  />
                  <span>{t('Individual')}</span>
                </label>
                <label
                  htmlFor={`company-check`}
                  className="flex gap-1 items-center"
                >
                  <input
                    type="checkbox"
                    name="type"
                    id={`company-check`}
                    className="accent-[#094752] w-5 h-5 border-surface rounded-lg"
                    checked={data.type === 2}
                    onChange={(event) => {}}
                    disabled
                  />
                  <span>{t('Corporation')}</span>
                </label>
              </div>
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Company name')}
                placeholder={'Enter your company name'}
                isRequired
                className="w-full"
                id="companyName"
                value={data.companyName}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your name')}
                placeholder={'Enter your name'}
                isRequired
                className="w-full"
                id="customerName"
                value={data.customerName}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your email')}
                placeholder="Enter your email"
                isRequired
                className="w-full"
                id="email"
                value={data.email}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your phone')}
                placeholder="Enter your phone"
                isRequired
                className="w-full"
                id="phone"
                value={data.phone}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-3">
              <FormFieldTextArea
                isFixedValue={true}
                label={t('Paypal/Bank account information (commission)')}
                className="w-full"
                placeholder="Anything else we should know?"
                id="Remark"
                row={4}
                value={data.description}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
          </>
        );
      case 3:
        const listServices: OptionInfo<string>[] = [];
        const valueService: string[] = [];
        data.additionalService?.forEach((item) => {
          listServices.push({
            value: item.name,
            label: item.name,
          });
          valueService.push(item.name);
        });
        return (
          <>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your name')}
                placeholder="Enter your name"
                isRequired
                className="w-full"
                id="customerName"
                value={data.customerName}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your email')}
                placeholder="Enter your email"
                isRequired
                className="w-full"
                id="email"
                value={data.email}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your phone')}
                placeholder="Enter your phone"
                isRequired
                className="w-full"
                id="phone"
                value={data.phone}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldMultiSelect
                isFixedValue={true}
                optionInfos={listServices}
                placeholder="Select a service"
                isRequired
                label={t('Chose service')}
                id="service"
                value={valueService}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>

            <div className="col-span-1">
              <FormFieldSelect
                isFixedValue={true}
                optionInfos={NATION_INFOS}
                placeholder="Select a nation"
                isRequired
                label={t('Nation')}
                className="w-full"
                id="nation"
                value={data.nation}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-3">
              <FormFieldTextArea
                isFixedValue={true}
                label={t('Remark')}
                className="w-full"
                placeholder="Anything else we should know?"
                id="Remark"
                row={4}
                value={data.description}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
          </>
        );
      case 4:
        const listServicesCore: OptionInfo<string>[] = [];
        const valueServiceCore: string[] = [];
        data.masterService?.forEach((item) => {
          listServicesCore.push({
            value: item.serviceName,
            label: item.serviceName,
          });
          valueServiceCore.push(item.serviceName);
        });
        return (
          <>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your name')}
                placeholder="Enter your name"
                isRequired
                className="w-full"
                id="customerName"
                value={data.customerName}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your email')}
                placeholder="Enter your email"
                isRequired
                className="w-full"
                id="email"
                value={data.email}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldText
                isFixedValue={true}
                label={t('Your phone')}
                placeholder="Enter your phone"
                isRequired
                className="w-full"
                id="phone"
                value={data.phone}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldMultiSelect
                isFixedValue={true}
                optionInfos={listServicesCore}
                placeholder="Select a service"
                isRequired
                label={t('Chose service')}
                id="service"
                value={valueServiceCore}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldSelect
                isFixedValue={true}
                optionInfos={NATION_INFOS}
                placeholder="Select a nation"
                isRequired
                label={t('Nation')}
                className="w-full"
                id="nation"
                value={data.nation}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-1">
              <FormFieldSelect
                isFixedValue={true}
                optionInfos={[]}
                placeholder="Select a state"
                isRequired
                label={t('States')}
                className="w-full"
                id="state"
                value={data.state}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
            <div className="col-span-3">
              <FormFieldTextArea
                isFixedValue={true}
                label={t('Remark')}
                className="w-full"
                placeholder="Anything else we should know?"
                id="Remark"
                row={4}
                value={data.description}
                validateCaller={{}}
                onChange={(e) => {}}
              />
            </div>
          </>
        );

      default:
        return;
    }
  };

  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex justify-between items-center border-b-2 pb-4">
        <div className={'text-h4 w-full text-start'}>
          {dataConfig.titlePopup}
        </div>
        <IconX className=" cursor-pointer" onClick={onClose} />
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex justify-end gap-4">
          <div className="w-[200px]">
            <FormFieldSelect
              id={'pic'}
              label={t('Person in charge')}
              onChange={(e) => handleUpdateInfo(e, 'pic')}
              validateCaller={{}}
              optionInfos={PIC_OPTION}
              value={data.pic}
              className="w-full"
              isFixedValue={!isAdmin}
            />
          </div>
          <div className="w-[150px]">
            <FormFieldSelect
              id={'status'}
              label={t('Status')}
              onChange={(e) => handleUpdateInfo(e, 'status')}
              validateCaller={{}}
              optionInfos={SERVICE_STEP_STATUS}
              isFixedValue={data.status === Status.ISSUED}
              value={data.status}
              className="w-full"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 mb-4">
          {handleRenderBody()}
        </div>
      </div>
    </div>
  );
};

export default DialogRequestDetail;
