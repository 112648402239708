import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import ButtonCs from './ButtonCs';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { getRandomColor } from '../helpers/helpers';
import { FormFieldText } from './FormFieldText';
import { IconAddSquare, IconAddSquareOutLine, IconTrash, IconX } from './icons';
import { Checkbox } from '@mui/material';
import { IUser } from './ServiceStepContent';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  path: string;
  onOk: () => void;
  onClose: () => void;
  signConfig: { lock_order: boolean; users: IUser[] };
  setSignConfig: (value: { lock_order: boolean; users: IUser[] }) => void;
}

export function SinglePagePDF(props: IProps) {
  const DEFAULT_POSITION = {
    id: uuidv4(),
    page: 0,
    position: '',
    isActive: false,
  };
  const DEFAULT_SIGNER = {
    id: uuidv4(),
    color: getRandomColor(),
    email: '',
    name: '',
    access_code: '',
    element: [DEFAULT_POSITION],
  };
  const { path, onClose, signConfig, setSignConfig, onOk } = props;
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const docRef = useRef<any>(null);
  const usersRef = useRef<IUser[]>(signConfig.users);

  const isDisabled = signConfig.users.some(
    (user) =>
      !user.email || !user.name || user.element.some((el) => !el.position),
  );

  useEffect(() => {
    if (!docRef.current || !docRef) return;
    const currentPageElm =
      docRef.current.getElementsByClassName('react-pdf__Page')[0];
    currentPageElm &&
      currentPageElm.addEventListener('dblclick', (event: any) =>
        printMousePos(event, currentPageElm),
      );
    return () => currentPageElm?.removeEventListener('dblclick', printMousePos);
  }, [docRef.current]);

  useEffect(() => {
    usersRef.current = signConfig.users;
  }, [signConfig.users]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function printMousePos(event: any, element: any) {
    const tempUsers = [...usersRef.current];
    const activeUser = tempUsers.find((user) =>
      user.element.some((el) => el.isActive),
    );
    if (!activeUser) return;
    const activeElm = activeUser.element.find((el) => el.isActive);
    if (!activeElm) return;
    activeElm.position = `${
      event.clientX - element.getBoundingClientRect().x
    } ${element.getBoundingClientRect().y - event.clientY}`;
    activeElm.page = element.getAttribute('data-page-number');
    setSignConfig({ ...signConfig, users: tempUsers });
  }

  const handleChangeFormUser = (
    value: string,
    id: string,
    field: 'name' | 'email' | 'access_code',
  ) => {
    const tempUsers = [...signConfig.users];
    const changeItem = tempUsers.find((user) => user.id === id);
    if (!changeItem) return;
    changeItem[field] = value;
    setSignConfig({ ...signConfig, users: tempUsers });
  };

  const handleAddSigner = () => {
    const tempUsers = [...signConfig.users];
    tempUsers.push(DEFAULT_SIGNER);
    setSignConfig({ ...signConfig, users: tempUsers });
  };

  const handleDeleteSigner = (id: string) => {
    if (signConfig.users.length === 1) return;
    let tempUsers = [...signConfig.users];
    tempUsers = tempUsers.filter((tempUser) => tempUser.id !== id);
    setSignConfig({ ...signConfig, users: tempUsers });
  };

  const handleAddPosition = (id: string) => {
    const tempUsers = [...signConfig.users];
    const currentUser = tempUsers.find((temp) => temp.id === id);
    if (!currentUser) return;
    currentUser.element.push(DEFAULT_POSITION);
    setSignConfig({ ...signConfig, users: tempUsers });
  };

  const handleDeletePosition = (idUser: string, idPos: string) => {
    const tempUsers = [...signConfig.users];
    const currentUser = tempUsers.find((temp) => temp.id === idUser);
    if (!currentUser) return;
    currentUser.element = currentUser.element.filter((el) => el.id !== idPos);
    setSignConfig({ ...signConfig, users: tempUsers });
  };

  const handleSelectPosition = (idUser: string, idPos: string) => {
    const tempUsers = [...signConfig.users];
    let currentUser = DEFAULT_SIGNER;
    tempUsers.forEach((temp) => {
      if (temp.id === idUser) currentUser = temp;
      else temp.element.forEach((el) => (el.isActive = false));
    });
    if (!currentUser) return;
    currentUser.element.forEach((el) => {
      if (el.id === idPos) el.isActive = !el.isActive;
      else el.isActive = false;
    });
    setSignConfig({ ...signConfig, users: tempUsers });
  };

  return (
    <div className="flex p-4 gap-2">
      <div ref={docRef} className="relative">
        {signConfig.users.map((user) => {
          return user.element.map((el, index) => {
            const arrPosi = el.position.split(' ');
            return (
              <div
                key={el.id}
                style={{
                  borderColor: user.color,
                  color: user.color,
                  left: `${arrPosi[0]}px`,
                  top: `${-arrPosi[1]}px`,
                }}
                className={`z-10 h-6 w-6 rounded-full border-2 absolute flex justify-center items-center ${
                  +el.page === pageNumber ? 'block' : 'hidden'
                }`}
              >
                {index + 1}
              </div>
            );
          });
        })}
        <Document
          file={path}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onClose}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <div className=" flex flex-col gap-2 justify-center items-center">
          <p>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p>
          <div className="flex gap-4">
            <ButtonCs
              type="submit"
              onClick={previousPage}
              disabled={pageNumber <= 1}
            >
              {t('Previous')}
            </ButtonCs>
            <ButtonCs
              type="submit"
              onClick={nextPage}
              disabled={pageNumber === numPages}
            >
              {t('Next')}
            </ButtonCs>
          </div>
        </div>
      </div>
      <div className="p-4 w-full border-2 rounded-lg">
        <div className="flex justify-between">
          <div className="flex justify-start items-center">
            <span className="font-bold text-[18px]">Lock Order</span>
            <Checkbox
              value={signConfig.lock_order}
              onChange={(e) =>
                setSignConfig({ ...signConfig, lock_order: e.target.checked })
              }
            />
          </div>
          <div className="flex gap-2">
            <ButtonCs
              type="reset"
              onClick={onClose}
              className="bg-white text-[#094752] border-2 rounded-lg border-[#094752]"
            >
              {t('Cancel')}
            </ButtonCs>
            <ButtonCs
              type="submit"
              onClick={onOk}
              disabled={isDisabled}
              className={`${isDisabled ? 'opacity-40' : ''}`}
            >
              {t('Send')}
            </ButtonCs>
          </div>
        </div>
        {signConfig.users.map((user) => {
          return (
            <div
              key={user.id}
              className="mt-4 border-2 p-4 w-auto items-start rounded-lg flex flex-col gap-2 relative"
            >
              <div className=" rounded-lg absolute top-2 -right-4 z-10 p-1 bg-white">
                <IconAddSquareOutLine
                  className="cursor-pointer mb-2"
                  onClick={handleAddSigner}
                />
                <IconTrash
                  className={`w-6  ${
                    signConfig.users.length === 1 ? 'hidden' : 'cursor-pointer'
                  }`}
                  onClick={() => handleDeleteSigner(user.id)}
                />
              </div>
              <div className="w-full">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <FormFieldText
                      isFixedValue={false}
                      label={t('Signer Email')}
                      isRequired
                      className="w-full"
                      id="mail"
                      errorMessage={t('This field is required')}
                      value={user.email}
                      validateCaller={{}}
                      onChange={(value) =>
                        handleChangeFormUser(value, user.id, 'email')
                      }
                    />
                    <FormFieldText
                      isFixedValue={false}
                      label={t('Signer Name')}
                      isRequired
                      className="w-full"
                      id="name"
                      errorMessage={t('This field is required')}
                      value={user.name}
                      validateCaller={{}}
                      onChange={(value) =>
                        handleChangeFormUser(value, user.id, 'name')
                      }
                    />
                    <FormFieldText
                      isFixedValue={false}
                      label={t('Access Code')}
                      className="w-full"
                      id="name"
                      errorMessage={t('This field is required')}
                      value={user.access_code}
                      validateCaller={{}}
                      onChange={(value) =>
                        handleChangeFormUser(value, user.id, 'access_code')
                      }
                    />
                  </div>
                  <div className="w-full min-h-[72px] flex flex-col gap-2">
                    <span className="font-bold w-full flex gap-2">
                      Sign Position{' '}
                      <IconAddSquare
                        className="cursor-pointer"
                        onClick={() => handleAddPosition(user.id)}
                      />
                    </span>
                    <div className="grid grid-cols-1 xl:grid-cols-5 gap-2 w-full">
                      {user.element.map((el, index) => {
                        return (
                          <div
                            key={index}
                            className="flex gap-2 items-center col-span-1"
                          >
                            <div
                              className="border-[1px] rounded-lg p-2 flex justify-between items-center w-[120px]"
                              onClick={() =>
                                handleSelectPosition(user.id, el.id)
                              }
                              style={{
                                backgroundColor: el.isActive ? user.color : '',
                                borderColor: el.page !== 0 ? user.color : '',
                              }}
                            >
                              <span>
                                {' '}
                                {index + 1}
                                {'. '}Page: {el.page}
                              </span>
                              <IconX
                                className={`w-2  ${
                                  user.element.length === 1
                                    ? 'hidden'
                                    : 'cursor-pointer'
                                }`}
                                onClick={() =>
                                  handleDeletePosition(user.id, el.id)
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-2 justify-end w-full">
                <div
                  className="h-10 w-10 pr-10 rounded-full"
                  style={{
                    backgroundColor: user.color,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
