import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LandingHome from '../components/landing-content/LandingHome';
import ButtonCs from '../components/ButtonCs';
import LandingServices from '../components/landing-content/LandingServices';
import LandingContactUs from '../components/landing-content/LandingContactUs';
import LandingAboutUs from '../components/landing-content/LandingAboutUs';
import { useApiGetLandingContent } from '../hooks/api/landing';
import { LandingContentResponse } from '../api/types';
import { callApiUpdateLandingContent } from '../api/landingRequest';
import { DialogSuccessFullscreen } from '../components/DialogFormStatusFullscreen';

const TAB_CONTENT = [
  { id: 1, tab: 'Home' },
  { id: 2, tab: 'Services' },
  { id: 3, tab: 'About Us' },
  { id: 4, tab: 'Contact Us' },
];

const LandingManagement = () => {
  const [currentTab, setCurrentTab] = useState<string>('Home');
  const [content, setContent] = useState<LandingContentResponse>();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const { data, refetch } = useApiGetLandingContent();

  useEffect(() => {
    if (!data) return;
    setContent(data);
  }, [data]);

  const handleSave = async () => {
    const tempContent = { ...content };
    tempContent.SERVICE?.ADDON_SERVICE?.value?.forEach((item) => {
      if (!item.level) item.id = undefined;
    });
    await callApiUpdateLandingContent(tempContent);
    refetch();
    setShowSuccessModal(true);
  };

  const renderBody = () => {
    switch (currentTab) {
      case 'Services':
        return (
          <LandingServices
            data={content?.SERVICE}
            onChange={(value) => setContent({ ...content, SERVICE: value })}
          />
        );
      case 'About Us':
        return (
          <LandingAboutUs
            data={content?.ABOUT_US}
            onChange={(value) => setContent({ ...content, ABOUT_US: value })}
          />
        );
      case 'Contact Us':
        return (
          <LandingContactUs
            data={content?.CONTACT_US}
            onChange={(value) => setContent({ ...content, CONTACT_US: value })}
          />
        );
      default:
        return (
          <LandingHome
            data={content?.HOME}
            onChange={(value) => setContent({ ...content, HOME: value })}
          />
        );
    }
  };
  return (
    <div className={'w-full grow flex flex-col p-3'}>
      <div
        className={
          'flex flex-col grow overflow-auto bg-white rounded justify-start items-center py-6 px-4 sm:px-8'
        }
      >
        <div className={'text-h4 w-full text-start mb-6'}>
          {t('Content Management')}
        </div>
        <div
          className={`mt-8 flex h-10 w-full gap-25  font-bold text-[#6D6F7F] text-[20px] `}
        >
          {TAB_CONTENT.map(({ id, tab }) => {
            const activeClass = 'border-b-2 border-[#000] text-[#000]';
            const isActive = tab === currentTab;
            return (
              <div
                className={`${
                  isActive ? activeClass : ''
                }  w-1/3 cursor-pointer items-center text-nowrap text-center `}
                key={id}
                onClick={() => setCurrentTab(tab)}
              >
                {tab}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col gap-4 mt-8 w-full h-full overflow-auto border-2 p-4 rounded-2xl">
          {renderBody()}
        </div>
        <div className=" shrink-0 w-full flex justify-end gap-2 items-center mt-8">
          <ButtonCs
            className="border border-surface text-primary bg-transparent"
            onClick={() => refetch()}
          >
            {t('Cancel')}
          </ButtonCs>
          <ButtonCs onClick={handleSave}>{t('Save')}</ButtonCs>
        </div>
      </div>
      {showSuccessModal && (
        <DialogSuccessFullscreen
          onClose={() => setShowSuccessModal(false)}
          title="Update Successfully!"
          actionElement={
            <ButtonCs onClick={() => setShowSuccessModal(false)}>
              Close
            </ButtonCs>
          }
        />
      )}
    </div>
  );
};

export default LandingManagement;
