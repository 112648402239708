import { useTranslation } from 'react-i18next';
import { FormFieldText } from '../FormFieldText';
import { FormFieldTextArea } from '../FormFieldArea';
import { LandingAboutUsData } from '../../api/types';
import { ChangeEvent, useEffect, useState } from 'react';
import { cn } from '../../utils/cn.util';
import { CameraIcon } from '../icons';
import { getFile, uploadFile } from '../../api/upload';

interface IProps {
  data?: LandingAboutUsData;
  onChange: (value: LandingAboutUsData) => void;
}

const LandingAboutUs = (props: IProps) => {
  const { data, onChange } = props;
  const [imgUrl, setImgUrl] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!data?.OUR_ASSOCIATES?.fileId) return;
    const fetchImg = async () => {
      const blob = await getFile(data?.OUR_ASSOCIATES?.fileId ?? '', {
        download: false,
      });
      blob && setImgUrl(URL.createObjectURL(blob));
    };
    fetchImg();
  }, [data?.OUR_ASSOCIATES?.fileId]);

  const handleChangeImg = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files;
    if (!file) return;
    const res = await uploadFile(file, {isPublic: true});
    const temp = { ...data };
    if (!temp.OUR_ASSOCIATES) return;
    temp.OUR_ASSOCIATES.fileId = res.data[0];
    onChange(temp);
  };

  const renderAboutUs = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('About Us')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.ABOUT_US?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.ABOUT_US) return;
              temp.ABOUT_US.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.ABOUT_US?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp.ABOUT_US) return;
            temp.ABOUT_US.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
      </div>
    );
  };

  const renderMeetOurTeam = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Meet our team')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.MEET_OUT_TEAM?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.MEET_OUT_TEAM) return;
              temp.MEET_OUT_TEAM.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.MEET_OUT_TEAM?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp.MEET_OUT_TEAM) return;
            temp.MEET_OUT_TEAM.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
      </div>
    );
  };

  const renderOurMission = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Our Mission')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.OUR_MISSION?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.OUR_MISSION) return;
              temp.OUR_MISSION.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.OUR_MISSION?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp.OUR_MISSION) return;
            temp.OUR_MISSION.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
      </div>
    );
  };

  const renderOurVision = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Our Vision')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.OUR_VISION?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.OUR_VISION) return;
              temp.OUR_VISION.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.OUR_VISION?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp.OUR_VISION) return;
            temp.OUR_VISION.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
      </div>
    );
  };

  const renderOurAssociates = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Our Associates')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.OUR_ASSOCIATES?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.OUR_ASSOCIATES) return;
              temp.OUR_ASSOCIATES.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <p className="flex text-cBase font-bold gap-1 -mb-2">
          <span>{t('Description')}</span>
        </p>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1">
            <div className="w-full h-full relative flex justify-center items-center border-[1px] rounded-xl">
              <img src={imgUrl} alt="" className="object-cover " />
              <label
                htmlFor="avt"
                className={cn(
                  'absolute bottom-0 right-0 rounded-full bg-gray-100 p-1 shadow-sm cursor-pointer',
                )}
              >
                <CameraIcon />
                <input
                  className="hidden"
                  id="avt"
                  type="file"
                  accept="*"
                  onChange={handleChangeImg}
                />
              </label>
            </div>
          </div>
          <div className=" col-span-2">
            <FormFieldTextArea
              className="w-full"
              id="description"
              placeholder="Enter description"
              value={data?.OUR_ASSOCIATES?.description}
              row={5}
              maxLength={1000}
              onChange={(e) => {
                const temp = { ...data };
                if (!temp.OUR_ASSOCIATES) return;
                temp.OUR_ASSOCIATES.description = e;
                onChange(temp);
              }}
              validateCaller={{}}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {renderAboutUs()}
      {renderMeetOurTeam()}
      {renderOurMission()}
      {renderOurVision()}
      {renderOurAssociates()}
    </>
  );
};

export default LandingAboutUs;
