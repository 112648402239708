import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiSearchPaidServiceType } from '../api/types';
import { EMPTY_SEARCH } from '../types/service';
import ButtonCs from './ButtonCs';
import { FormFieldText } from './FormFieldText';

type Props = {
  onSubmit: (data: any) => void;
  onReset: () => void;
  loading?: boolean;
};

export function ServiceFilter({ onSubmit, onReset, loading }: Props) {
  const { t } = useTranslation();

  const [dataFilter, setDataFilter] =
    useState<ApiSearchPaidServiceType>(EMPTY_SEARCH);

  const updateField = (field: string, data: any) => {
    setDataFilter((pre) => ({
      ...pre,
      [field]: data,
    }));
  };

  function reset(): void {
    setDataFilter(EMPTY_SEARCH);
    onReset && onReset();
  }

  const onSubmitForm = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit && onSubmit(dataFilter);
  };

  return (
    <div className={'w-full flex flex-row justify-start items-end gap-4 mb-4'}>
      <FormFieldText
        label={t('Customer Name')}
        id="customerName"
        validateCaller={{}}
        onChange={(value) => updateField('customerName', value)}
        value={dataFilter.customerName}
        placeholder={t('Input customer name')}
      />
      <FormFieldText
        label={t('Order ID')}
        id="orderId"
        validateCaller={{}}
        placeholder={t('Input paid service ID')}
        onChange={(value) => updateField('paidServiceId', value)}
        value={dataFilter.paidServiceId}
      />
      <div className="flex gap-2">
        <ButtonCs
          type="button"
          className="bg-transparent border border-surface text-primary"
          onClick={reset}
        >
          {t('Reset')}
        </ButtonCs>
        <ButtonCs type="submit" onClick={onSubmitForm}>
          {t('Apply')}
        </ButtonCs>
      </div>
    </div>
  );
}
