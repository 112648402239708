import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from '@emotion/styled';
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

const FormDatePicker = styled(DatePicker)`
  .MuiInputBase-input {
    padding: 8px !important;
    overflow: hidden !important;
  }
` as typeof DatePicker;

interface IProps {
  onChange: (
    value: Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>,
  ) => void;
  value: Dayjs | null;
  label?: string;
  isRequired?: boolean;
  readOnly?: boolean;
  disablePast?: boolean;
}

const FormDateTime = (props: IProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <p className="flex">
          {props.label && <p className="font-bold mb-2">{props.label}</p>}
          {props?.isRequired && (
            <p className="font-bold mb-2 text-[red] ml-1">*</p>
          )}
        </p>
        <FormDatePicker
          className="h-[40px]  w-full"
          onChange={props.onChange}
          value={props.value}
          disablePast={Boolean(props.disablePast)}
          readOnly={props.readOnly}
        />
      </div>
    </LocalizationProvider>
  );
};

export default FormDateTime;
