import { RequestManagementProps } from '../hooks/api/request';
import { callApi } from '../services-base/api';
import { RawPagingApiResult } from './types';

type ParamsUpdateQuotation = {
  id: number,
  price: number,
  dueDate: string
}

export async function callApiGetQuotationList(body: RequestManagementProps) {
  const pathURL = '/api/admin/findAllQuotation';
  const rawResult = await callApi<RawPagingApiResult<any>>(
    'POST',
    pathURL,
    body,
    true,
  );
  return rawResult;
}

export async function callApiUpdateQuotation(body: ParamsUpdateQuotation) {
  const pathURL = '/api/admin/updateQuotation';
  await callApi<RawPagingApiResult<any>>(
    'POST',
    pathURL,
    body,
    true,
  );
}
