type GenKeyFn = (...keys: any) => unknown[];

export const QueryKeyApi = {
  getAllUsers: 'get-users',
  getKYCs: 'get-KYCs',
  searchUsers: 'search-users',
  getOrders: 'get-orders',
  searchUserByRole:'search-user-by-role',
  getCompany:'get-company',
  getRequest: 'get-request',
  getQuotation: 'get-quotation',
  getContentLanding: 'get-content',
};

const MutationKey = {
  createUser: 'create-user',
}

export const KeyFactory = {
  getAllUsers: (...args: unknown[]) => [QueryKeyApi.getAllUsers, ...args],
  createUser: (...args: unknown[]) => [MutationKey.createUser, ...args],
  getKYCs: (...args: unknown[]) => [QueryKeyApi.getKYCs, ...args],
  searchUsers: (...args: unknown[]) => [QueryKeyApi.searchUsers, ...args],
  getOrders: (...args: unknown[]) => [QueryKeyApi.getOrders, ...args],
  searchUserByRole: (...args: unknown[]) => [QueryKeyApi.searchUserByRole, ...args],
  getCompany: (...args: unknown[]) => [QueryKeyApi.getCompany, ...args],
  getRequest: (...args: unknown[]) => [QueryKeyApi.getRequest, ...args],
  getQuotation: (...args: unknown[]) => [QueryKeyApi.getQuotation, ...args],
  getContentLanding: (...args: unknown[]) => [QueryKeyApi.getContentLanding, ...args],
} as const;
