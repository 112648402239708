import { IconCheck, IconInfoCircle, IconRefreshCircle } from "../components/icons";

export enum Status {
  PENDING = 'Pending',
  IN_PROGRESS = 'In-Progress',
  CONFIRMED = 'Confirmed',
  APPROVED = 'Approved',
  ISSUED = 'Issued',
  READY = 'Ready',
  REJECTED = 'Rejected',
  ACTIVE = 'Active',
  IN_ACTIVE = 'Inactive',
  DELCINED = 'Declined',
  SIGNING = 'Signing',
  EXPIRED = 'Expired'
}

export const StatusBackgroundClassNameMap: Record<string, string> = {
  [Status.PENDING]: 'bg-pending_bg',
  [Status.SIGNING]: 'bg-pending_bg',
  [Status.IN_PROGRESS]: 'bg-in_progress_bg',
  [Status.APPROVED]: 'bg-green-100',
  [Status.CONFIRMED]: 'bg-green-100',
  [Status.ISSUED]: "bg-green-100",
  [Status.READY]: 'bg-green-100',
  [Status.REJECTED]: 'bg-[#f54554]',
  [Status.ACTIVE]: 'bg-green-100',
  [Status.IN_ACTIVE]: 'bg-in_progress_bg',
  [Status.DELCINED]: 'bg-[#f54554]',
  [Status.EXPIRED]: 'bg-[#f54554]',
};

export const StatusTextClassNameMap: Record<string, string> = {
  [Status.PENDING]: 'text-pending_text',
  [Status.SIGNING]: 'text-pending_text',
  [Status.IN_PROGRESS]: '',
  [Status.APPROVED]: 'text-green-500',
  [Status.CONFIRMED]: 'text-green-500',
  [Status.ISSUED]: 'text-green-700',
  [Status.READY]: 'text-green-700',
  [Status.REJECTED]: 'text-white',
  [Status.ACTIVE]: 'text-green-700',
  [Status.IN_ACTIVE]: '',
};

export const StatusDotClassNameMap: Record<string, string> = {
  [Status.PENDING]: 'bg-pending_text',
  [Status.IN_PROGRESS]: 'bg-pending_text',
  [Status.APPROVED]: 'bg-green-400',
  [Status.CONFIRMED]: 'bg-green-400',
  [Status.ISSUED]: "bg-green-400",
  [Status.READY]: 'bg-green-400',
  [Status.ACTIVE]: 'bg-green-400',
};

export const StatusIconMap: Record<string, React.ReactNode> = {
  [Status.PENDING]: <IconInfoCircle width={20} height={20}/>,
  [Status.IN_PROGRESS]: <IconRefreshCircle width={20} height={20}/>,
  [Status.APPROVED]: <IconCheck width={20} height={20}/>,
  [Status.CONFIRMED]: <IconCheck width={20} height={20}/>,
  [Status.ISSUED]: <IconCheck width={20} height={20}/>,
  [Status.READY]: <IconCheck width={20} height={20}/>,
};