import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogConfirmFullScreen,
  DialogSuccessFullscreen,
} from '../components/DialogFormStatusFullscreen';
import {
  useApiApproveKYC,
  useApiGetKYCs,
  useApiRejectKYC,
} from '../hooks/api/kyc';
import { generateFormatDate } from '../services-ui/date';
import { getFile, useApiGetFileName } from '../api/upload';
import { KycDetail } from '../api/types';
import { StatusBadge } from '../components/StatusBadge';
import { Status } from '../constants/StatusBadge';
import { FormFieldText } from '../components/FormFieldText';
import ButtonCs from '../components/ButtonCs';
import { useValidateCaller } from '../hooks-ui/useValidateCaller';
import { handleExportData } from '../services-base/api';
import { TAB_EXPORT } from '../constants/global';
import { DownloadIcon, IconArrowRight, IconX } from '../components/icons';
import MediaPreview from '../components/MediaPreview';
import { DialogContainer } from '../components/DialogContainer';

type Props = {};

const DEFAULT_SEARCH = {
  email: '',
  phone: '',
  customerName: '',
};

export function KycContent(props: Props) {
  const translation = useTranslation();
  const { validateCaller } = useValidateCaller();

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });
  const [shouldShowConfirmDialog, setShouldShowConfirmDialog] = useState<
    false | 'approve' | 'reject'
  >();
  const [idSelected, setIdSelected] = useState<number>();
  const [pictureSrc, setPictureSrc] = useState<{ id: string; url: string }>({
    id: '',
    url: '',
  });
  const [pictureSrcList, setPictureSrcList] = useState<string[]>();
  const [videoSrc, setVideoSrc] = useState<{ [key in string]: string }>({});
  const [dataSearch, setDataSearch] = useState<{
    [key in string]: string;
  }>(DEFAULT_SEARCH);

  const [showSuccessDialog, setShowSuccessDialog] = useState<
    false | 'approve' | 'reject'
  >(false);

  const { data, isLoading, refetch } = useApiGetKYCs({
    page: paginationModel.page,
    size: paginationModel.pageSize,
    email: dataSearch.email,
    phone: dataSearch.phone,
    customerName: dataSearch.customerName,
  });

  const { data: fileData } = useApiGetFileName(
    [pictureSrc.id],
    !!pictureSrc.id,
  );

  const tableData = useMemo(
    () =>
      data?.content.filter((item) => item.roles[0].name === 'ROLE_USER') ?? [],
    [data],
  );
  const kycCount = data?.totalElements;

  const { mutateAsync: approveKYC, isPending: approvingKYC } =
    useApiApproveKYC();
  const { mutateAsync: rejectKYC, isPending: rejectingKYC } = useApiRejectKYC();

  const handleClickReject = (id: number) => {
    setIdSelected(id);
    setShouldShowConfirmDialog('reject');
  };

  const handleClickApproved = (id: number) => {
    setIdSelected(id);
    setShouldShowConfirmDialog('approve');
  };

  const handleCancel = () => {
    setIdSelected(undefined);
    setShouldShowConfirmDialog(false);
  };

  const handleConfirm = async () => {
    if (!idSelected) return;

    setShouldShowConfirmDialog(false);

    if (shouldShowConfirmDialog === 'approve') {
      await approveKYC(idSelected);
      setShowSuccessDialog('approve');
      await refetch();
      return;
    } else {
      await rejectKYC(idSelected);
      setShowSuccessDialog('reject');
      await refetch();
      return;
    }
  };

  const handleReset = () => {
    setDataSearch(DEFAULT_SEARCH);
    setTimeout(refetch);
  };

  const handleSearch = () => {
    refetch();
  };

  const handleExport = () => handleExportData(TAB_EXPORT.KYC_REQUEST);

  async function handleClickPhoto(
    kyc: KycDetail,
    type: 'passport' | 'holdPassport',
  ) {
    if (
      type === 'passport' &&
      kyc?.passport &&
      kyc.llcInNation !== 'United States'
    ) {
      const blob = await getFile(kyc.passport, { download: false });
      blob && setVideoSrc({ [kyc.passport]: URL.createObjectURL(blob) });
    }

    if (type === 'holdPassport' && kyc?.pictureHoldPassport) {
      const idList = kyc?.pictureHoldPassport.split(',');
      const blob = await getFile(idList[0], { download: false });
      let previewUrl = '';
      if (blob) previewUrl = URL.createObjectURL(blob);
      setPictureSrc({
        id: idList[0],
        url: previewUrl,
      });
      setPictureSrcList(idList);
    }
  }

  const handleShowNextImg = async () => {
    if (!pictureSrcList) return;
    const index = pictureSrcList.indexOf(pictureSrc.id);
    let nextIndex = 0;
    if (index < pictureSrcList?.length - 1) nextIndex = index + 1;
    const blob = await getFile(pictureSrcList[nextIndex], { download: false });
    let previewUrl = '';
    if (blob) previewUrl = URL.createObjectURL(blob);
    setPictureSrc({
      id: pictureSrcList[nextIndex],
      url: previewUrl,
    });
  };

  const handleShowBackImg = async () => {
    if (!pictureSrcList) return;
    const index = pictureSrcList.indexOf(pictureSrc.id);
    let previousIndex = pictureSrcList.length - 1;
    if (index > 1) previousIndex = index - 1;
    const blob = await getFile(pictureSrcList[previousIndex], {
      download: false,
    });
    let previewUrl = '';
    if (blob) previewUrl = URL.createObjectURL(blob);
    setPictureSrc({
      id: pictureSrcList[previousIndex],
      url: previewUrl,
    });
  };

  const handleDownload = () => {
    if (!Object.keys(videoSrc)[0]) return;
    getFile(Object.keys(videoSrc)[0]);
  };

  // TODO: add i18n for columns
  const kycColumns: GridColDef[] = [
    {
      field: 'kycStatus',
      headerName: 'Status',
      sortable: false,
      type: 'string',
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        const status = params.value;
        return <StatusBadge status={status as Status}></StatusBadge>;
      },
    },
    { field: 'userId', headerName: 'User ID', width: 100 },
    {
      field: 'name',
      headerName: 'Customer Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: false,
      type: 'string',
      width: 160,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.codePhone || ''} ${params.row.phone || ''}`,
    },
    {
      field: 'requestKYCAt',
      headerName: 'Requested on',
      sortable: false,
      type: 'string',
      width: 120,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.requestKYCAt
          ? `${generateFormatDate(new Date(params.row.requestKYCAt))}`
          : '',
    },
    {
      field: 'photos',
      headerName: 'Identify Photos',
      sortable: false,
      type: 'string',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className={'flex flex-col gap-1'}>
            {params.row?.pictureHoldPassport && (
              <p
                className={'underline text-blue-500 cursor-pointer'}
                onClick={() => handleClickPhoto(params.row, 'holdPassport')}
              >
                {translation.t('Passport')}
              </p>
            )}
            {params.row?.passport && (
              <p
                className={`underline text-blue-500 cursor-pointer ${
                  params.row.llcInNation !== 'United States'
                    ? ''
                    : 'cursor-not-allowed opacity-50'
                }`}
                onClick={() => handleClickPhoto(params.row, 'passport')}
              >
                {translation.t('Hold Passport')}
              </p>
            )}
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      type: 'string',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const status = params.row?.kycStatus;
        const isDisabled = status !== 'In-Progress';
        const classDisabled =
          '!bg-[rgba(0,_0,_0,_0.12)] !text-[rgba(0,_0,_0,_0.26)] pointer-events-none';
        return (
          <div className={'flex flex-row gap-3'}>
            <button
              onClick={() => handleClickReject(params.row.id)}
              className={`py-2 px-3 rounded-lg cursor-pointer bg-[#fa6673] hover:bg-[#f54554] text-white ${
                isDisabled ? classDisabled : ''
              }`}
              disabled={isDisabled}
            >
              {translation.t('Reject')}
            </button>
            <button
              onClick={() => handleClickApproved(params.row.id)}
              className={`py-2 px-3 rounded-lg cursor-pointer bg-green-100 hover:bg-green-200 text-success ${
                isDisabled ? classDisabled : ''
              }`}
              disabled={isDisabled}
            >
              {translation.t('Approve')}
            </button>
          </div>
        );
      },
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      sortable: false,
      type: 'string',
      width: 200,
      valueGetter: (params: GridValueGetterParams<any>) => {
        return params.row.updatedBy;
      },
    },
  ];

  return (
    <div className={'w-full grow flex flex-col p-3'}>
      <div
        className={
          'flex flex-col grow overflow-auto bg-white rounded justify-start items-center py-6 px-4 sm:px-8'
        }
      >
        <p className={'text-h4 w-full text-start mb-6'}>
          {translation.t('KYC Request List')}
        </p>
        <div
          className={'w-full grow'}
          key={tableData?.map((value) => value.id).join('_')}
        >
          <div
            className={
              'w-full flex flex-row justify-between items-center gap-10 mb-4'
            }
          >
            <div
              className={
                'w-full flex flex-row justify-start items-end gap-4 mb-4'
              }
            >
              <FormFieldText
                label={translation.t('Email')}
                id="email"
                validateCaller={validateCaller}
                onChange={(e) => setDataSearch({ ...dataSearch, email: e })}
                value={dataSearch.email}
                placeholder={translation.t('Input email')}
              />
              <FormFieldText
                label={translation.t('Phone')}
                id="phone"
                validateCaller={validateCaller}
                onChange={(e) => setDataSearch({ ...dataSearch, phone: e })}
                value={dataSearch.phone}
                placeholder={translation.t('Input phone number')}
              />
              <FormFieldText
                label={translation.t('Customer Name')}
                id="customerName"
                validateCaller={validateCaller}
                onChange={(e) =>
                  setDataSearch({ ...dataSearch, customerName: e })
                }
                value={dataSearch.customerName}
                placeholder={translation.t('Input customer name')}
              />
              <div className="flex gap-2">
                <ButtonCs
                  type="button"
                  className="bg-transparent border border-surface text-primary"
                  onClick={handleReset}
                  isLoading={isLoading}
                >
                  {translation.t('Reset')}
                </ButtonCs>
                <ButtonCs
                  isLoading={isLoading}
                  type="submit"
                  onClick={handleSearch}
                >
                  {translation.t('Apply')}
                </ButtonCs>
              </div>
            </div>
            <ButtonCs type="submit" className="h-[40px]" onClick={handleExport}>
              {translation.t('Export')}
            </ButtonCs>
          </div>
          <DataGrid
            paginationMode="server"
            rows={[...tableData].sort((a, b) => {
              if (a.kycStatus === b.kycStatus) return 0;
              if (a.kycStatus === 'In-Progress') return -1;
              if (b.kycStatus === 'In-Progress') return 1;
              return 0;
            })}
            columns={kycColumns}
            sx={{
              height: 'calc(100vh - 250px)',
            }}
            pageSizeOptions={[25]}
            rowCount={kycCount || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={(model) => setPaginationModel(model)}
          />
        </div>
      </div>
      {shouldShowConfirmDialog && (
        <DialogConfirmFullScreen
          onClose={() => setShouldShowConfirmDialog(false)}
          title={
            shouldShowConfirmDialog === 'approve'
              ? 'Approve KYC Request?'
              : 'Reject KYC Request?'
          }
          content={'This action cannot be undone!'}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}

      {pictureSrc.url && (
        <DialogContainer
          onClose={() => setPictureSrc({ id: '', url: '' })}
          isCloseOnClickOverlay
          isAutoSize
        >
          <div className="rounded-lg bg-white relative px-4">
            <IconArrowRight
              className="rotate-180 absolute left-0 top-[50%] size-8 rounded-full border-2 border-[#414040] cursor-pointer"
              onClick={handleShowBackImg}
            />
            <IconArrowRight
              className="size-8 rounded-full border-2 border-[#414040] absolute right-0 top-[50%] cursor-pointer "
              onClick={handleShowNextImg}
            />
            <div className="w-full justify-center items-center flex flex-col overflow-auto">
              <MediaPreview
                urlPreview={pictureSrc.url}
                fileType={fileData?.[0].fileType}
                onClose={() => setPictureSrc({ id: '', url: '' })}
                fileId={fileData?.[0].id}
              />
            </div>
          </div>
        </DialogContainer>
      )}

      {Object.keys(videoSrc)[0] && (
        <DialogContainer
          onClose={() => setVideoSrc({})}
          isCloseOnClickOverlay
          isAutoSize
        >
          <div className="w-[60vw] rounded-lg bg-white">
            <div className="w-full justify-center items-center py-8 px-4 flex flex-col">
              <div className="w-full mx-4 flex justify-center items-center flex-col gap-y-8 ">
                <div className="flex justify-end items-center w-full gap-4 -mt-4">
                  <DownloadIcon
                    className="cursor-pointer"
                    onClick={handleDownload}
                  />
                  <IconX
                    className="cursor-pointer scale-125"
                    onClick={() => setVideoSrc({})}
                  />
                </div>
                <video
                  src={Object.values(videoSrc)[0]}
                  controls
                  controlsList="nodownload"
                />
              </div>
            </div>
          </div>
        </DialogContainer>
      )}

      {showSuccessDialog && (
        <DialogSuccessFullscreen
          onClose={() => setShowSuccessDialog(false)}
          title={showSuccessDialog === 'approve' ? 'Approved' : 'Rejected'}
        />
      )}
    </div>
  );
}
