import { callApi } from '../services-base/api';
import { LandingContentResponse, RawPagingApiResult } from './types';

export async function callApiGetLandingContent() {
  const pathURL = '/api/public/landing-page/content';
  const rawResult = await callApi<LandingContentResponse>(
    'GET',
    pathURL,
    {},
    false,
  );
  return rawResult;
}

export async function callApiUpdateLandingContent(
  body: LandingContentResponse,
) {
  const pathURL = '/api/landing-page/updateContent';
  await callApi<LandingContentResponse>('POST', pathURL, body, true);
}
