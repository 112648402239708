import { useMutation, useQuery } from '@tanstack/react-query';
import { callApiCreateAdminAccount } from '../../api/account';
import {
  callApiLViewUser,
  callApiSearchUser,
  callApiSearchUserByRole,
} from '../../api/userManagement';
import { KeyFactory } from '../../services-base/key-factory';
import { ApiSearchUserByRole } from '../../api/types';

type UseGetUsersProps = {
  page: number;
  size: number;
  fullName: string;
  phone: string;
  email: string;
  userTab: number;
};

export const useApiGetUsers = (parmas: UseGetUsersProps) => {
  return useQuery({
    queryKey: KeyFactory.getAllUsers(parmas.page, parmas.size),
    queryFn: () => callApiLViewUser(parmas),
  });
};

export const useApiCreateUser = () => {
  return useMutation({
    mutationKey: KeyFactory.createUser(),
    mutationFn: callApiCreateAdminAccount,
  });
};

export const useApiUserSearchByRole = (body: ApiSearchUserByRole) => {
  return useQuery({
    queryKey: KeyFactory.searchUserByRole(body),
    queryFn: () => callApiSearchUserByRole(body),
  });
};
