import { FormFieldText } from '../FormFieldText';
import { useTranslation } from 'react-i18next';
import { FormFieldTextArea } from '../FormFieldArea';
import { LandingServivesData } from '../../api/types';
import { IconAddCircle, IconTrash } from '../icons';
import { FormFieldSelect } from '../FormFieldSelect';
import { useMemo } from 'react';

interface IProps {
  data?: LandingServivesData;
  onChange: (value: LandingServivesData) => void;
}

const LandingServices = (props: IProps) => {
  const { data, onChange } = props;
  const { t } = useTranslation();

  const valueServices = useMemo(() => {
    const result: {
      [key in
        | 'Singapore'
        | 'HK'
        | 'Colorado'
        | 'Wyoming'
        | 'Delaware']?: string;
    } = {
      Singapore: '',
      HK: '',
      Colorado: '',
      Wyoming: '',
      Delaware: '',
    };
    data?.CORE_SERVICE?.value?.forEach((value) => {
      if (value.description === 'Singapore') result.Singapore = value.title;
      else if (value.description === 'Hong Kong') result.HK = value.title;
      else if (value.description === 'Colorado') result.Colorado = value.title;
      else if (value.description === 'Wyoming') result.Wyoming = value.title;
      else result.Delaware = value.title;
    });
    return result;
  }, [data]);

  const dataServices = useMemo(() => {
    const result: {
      [key in 'US' | 'Singapore' | 'HK']: { value: string; label: string }[];
    } = {
      US: [],
      Singapore: [],
      HK: [],
    };
    data?.CORE_SERVICE?.service?.forEach((item) => {
      if (item.serviceType === 'Add-on') return;
      if (item.nation === 'United States')
        result.US.push({
          value: item.id.toString(),
          label: item.serviceName,
        });
      else if (item.nation === 'Singapore')
        result.Singapore.push({
          value: item.id.toString(),
          label: item.serviceName,
        });
      else
        result.HK.push({
          value: item.id.toString(),
          label: item.serviceName,
        });
    });
    return result;
  }, []);

  const handleAddService = () => {
    const temp = { ...data };
    if (!temp?.ADDON_SERVICE?.value) return;
    temp?.ADDON_SERVICE?.value.push({
      id: new Date().getTime(),
      title: '',
      description: '',
      type: 'SERVICE',
      blockType: 'ADDON_SERVICE',
    });
    onChange(temp);
  };

  const handleDeleteService = (id?: number) => {
    const temp = { ...data };
    if (!temp?.ADDON_SERVICE?.value) return;
    temp.ADDON_SERVICE.value = temp.ADDON_SERVICE.value.filter(
      (item) => item.id !== id,
    );
    onChange(temp);
  };

  const handleChangeNation = (value: string, field: number) => {
    const temp = { ...data };
    if (!temp?.CORE_SERVICE?.value) return;
    let dataTemp;
    switch (field) {
      case 1:
        dataTemp = temp.CORE_SERVICE.value.find(
          (el) => el.description === 'Singapore',
        );
        break;
      case 2:
        dataTemp = temp.CORE_SERVICE.value.find(
          (el) => el.description === 'Hong Kong',
        );
        break;
      case 3:
        dataTemp = temp.CORE_SERVICE.value.find(
          (el) => el.description === 'Colorado',
        );
        break;
      case 4:
        dataTemp = temp.CORE_SERVICE.value.find(
          (el) => el.description === 'Wyoming',
        );
        break;
      case 5:
        dataTemp = temp.CORE_SERVICE.value.find(
          (el) => el.description === 'Delaware',
        );
        break;
      default:
        break;
    }
    if (!dataTemp) return;
    dataTemp.title = value;
    onChange(temp);
  };

  const renderCore = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Core Services')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.CORE_SERVICE?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.CORE_SERVICE) return;
              temp.CORE_SERVICE.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.CORE_SERVICE?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp.CORE_SERVICE) return;
            temp.CORE_SERVICE.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
        <div className="border-[1px] rounded ">
          <div className="flex justify-between items-center border-b-[1px] p-2">
            <span className="font-bold">{t('Based-Services List')}</span>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 p-4">
            <div className="col-span-1 bg-slate-200 pl-4 flex items-center">
              {t('Singapore')}
            </div>
            <div className="col-span-3">
              <FormFieldSelect
                isFixedValue={false}
                optionInfos={dataServices.Singapore}
                isRequired
                className="w-full"
                placeholder="Choose main based-service"
                id="Singapore"
                value={valueServices.Singapore}
                validateCaller={{}}
                onChange={(value) => handleChangeNation(value, 1)}
              />
            </div>
            <div className="col-span-1 bg-slate-200 pl-4 flex items-center">
              {t('Hong Kong')}
            </div>
            <div className="col-span-3">
              <FormFieldSelect
                isFixedValue={false}
                optionInfos={dataServices.HK}
                isRequired
                className="w-full"
                placeholder="Choose main based-service"
                id="HK"
                value={valueServices.HK}
                validateCaller={{}}
                onChange={(value) => handleChangeNation(value, 2)}
              />
            </div>
            <div className="col-span-1 bg-slate-200 pl-4 flex items-start pt-2 h-10">
              {t('United State')}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 col-span-3">
              <div className="col-span-1 bg-slate-200 pl-4 flex items-center">
                {t('Colorado')}
              </div>
              <div className="col-span-2">
                <FormFieldSelect
                  isFixedValue={false}
                  optionInfos={dataServices.US}
                  isRequired
                  placeholder="Choose main based-service"
                  className="w-full"
                  id="Colorado"
                  value={valueServices.Colorado}
                  validateCaller={{}}
                  onChange={(value) => handleChangeNation(value, 3)}
                />
              </div>
              <div className="col-span-1 bg-slate-200 pl-4 flex items-center">
                {t('Wyoming')}
              </div>
              <div className="col-span-2">
                <FormFieldSelect
                  isFixedValue={false}
                  optionInfos={dataServices.US}
                  isRequired
                  placeholder="Choose main based-service"
                  className="w-full"
                  id="Wyoming"
                  value={valueServices.Wyoming}
                  validateCaller={{}}
                  onChange={(value) => handleChangeNation(value, 4)}
                />
              </div>
              <div className="col-span-1 bg-slate-200 pl-4 flex items-center">
                {t('Delaware')}
              </div>
              <div className="col-span-2">
                <FormFieldSelect
                  isFixedValue={false}
                  optionInfos={dataServices.US}
                  isRequired
                  placeholder="Choose main based-service"
                  className="w-full"
                  id="Delaware"
                  value={valueServices.Delaware}
                  validateCaller={{}}
                  onChange={(value) => handleChangeNation(value, 5)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAddon = () => {
    return (
      <div className=" w-full rounded-2xl border-2 p-4 flex flex-col gap-4">
        <span className="font-bold text-[18px]">{t('Add-on Services')}</span>
        <div className="w-1/3">
          <FormFieldText
            id={'titleHome'}
            label="Title"
            value={data?.ADDON_SERVICE?.title}
            onChange={(e) => {
              const temp = { ...data };
              if (!temp.ADDON_SERVICE) return;
              temp.ADDON_SERVICE.title = e;
              onChange(temp);
            }}
            maxLength={100}
            placeholder="Enter title"
            validateCaller={{}}
          />
        </div>
        <FormFieldTextArea
          label={t('Description')}
          className="w-full"
          id="description"
          placeholder="Enter description"
          value={data?.ADDON_SERVICE?.description}
          row={3}
          maxLength={1000}
          onChange={(e) => {
            const temp = { ...data };
            if (!temp.ADDON_SERVICE) return;
            temp.ADDON_SERVICE.description = e;
            onChange(temp);
          }}
          validateCaller={{}}
        />
        <div className="border-[1px] rounded ">
          <div className="flex justify-between items-center border-b-[1px] p-2">
            <span className="font-bold">{t('Value-add Services List')}</span>
            <IconAddCircle
              className="cursor-pointer"
              onClick={handleAddService}
            />
          </div>
          {data?.ADDON_SERVICE?.value?.map((item, index) => (
            <div
              key={item.id}
              className=" flex justify-between items-start p-4 gap-8 border-[1px]"
            >
              <div className="w-full flex gap-4">
                <span className=" size-10 rounded-full flex justify-center items-center bg-slate-300">
                  {index + 1}
                </span>
                <div className="w-full">
                  <div className="w-full mb-2">
                    <FormFieldText
                      id={'titleHome'}
                      label=""
                      value={item?.title}
                      onChange={(e) => {
                        const temp = { ...data };
                        if (!temp.ADDON_SERVICE?.value) return;
                        temp.ADDON_SERVICE.value[index].title = e;
                        onChange(temp);
                      }}
                      maxLength={100}
                      placeholder="Enter title"
                      validateCaller={{}}
                    />
                  </div>
                  <FormFieldTextArea
                    className="w-full"
                    id="description"
                    placeholder="Enter description"
                    value={item?.description}
                    row={3}
                    maxLength={1000}
                    onChange={(e) => {
                      const temp = { ...data };
                      if (!temp.ADDON_SERVICE?.value) return;
                      temp.ADDON_SERVICE.value[index].description = e;
                      onChange(temp);
                    }}
                    validateCaller={{}}
                  />
                </div>
              </div>
              <IconTrash
                className="cursor-pointer"
                onClick={() => handleDeleteService(item?.id)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };
  return (
    <>
      {renderCore()}
      {renderAddon()}
    </>
  );
};

export default LandingServices;
