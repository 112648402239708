import { FormFieldSelect } from '../../FormFieldSelect';
import { FormFieldText } from '../../FormFieldText';
import { useValidateCaller } from '../../../hooks-ui/useValidateCaller';
import { FormFieldTextArea } from '../../FormFieldArea';
import {
  APPLIED_STATES,
  CURRENCY,
  ENTITY_ENDING_HK,
  ENTITY_ENDING_INFOS_US,
  ENTITY_ENDING_SING,
  INDUSTRY_INFOS,
} from '../../../constants/SelectionOptions';
import { CompanyInformation } from '../../../api/types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import FormDateTime from '../../FormDateTime';
import { FormFieldNumber } from '../../FormFieldNumber';

type CompanyInformationTabProps = {
  readonly: boolean;
  companyInfo?: Partial<CompanyInformation>;
  onChange?: (companyInfo: Partial<CompanyInformation>) => void;
  nation?: string;
};

export function CompanyInformationTab({
  readonly,
  companyInfo,
  onChange,
  nation,
}: CompanyInformationTabProps) {
  const { t } = useTranslation();
  const { validateCaller } = useValidateCaller();
  const isUS = nation === 'United States';
  const isHK = nation === 'Hong Kong';
  const handleFormChange = <K extends keyof CompanyInformation>(
    key: K,
    value: CompanyInformation[K],
  ) => {
    const newInfo = { ...companyInfo, [key]: value };
    onChange?.(newInfo);
  };

  const renderOtherInfo = () => {
    return (
      <>
        <div>
          <FormFieldText
            isFixedValue={readonly}
            label={t('Company Number')}
            className="w-full"
            id="companyNumber"
            errorMessage={t('This field is required')}
            value={companyInfo?.companyNumber}
            validateCaller={validateCaller}
            onChange={(value) => handleFormChange('companyNumber', value)}
          />
        </div>
        <div className="min-w-full col-span-1 xl:col-span-3">
          <FormFieldTextArea
            isFixedValue={readonly}
            isRequired
            label={t('Company Description')}
            className="w-full"
            id="description"
            value={companyInfo?.description}
            validateCaller={{}}
            onChange={(value) => handleFormChange('description', value)}
          />
        </div>
      </>
    );
  };

  const renderUSInfo = () => {
    return (
      <>
        <div>
          <FormFieldText
            isFixedValue={readonly}
            label={t('LLC ID Number')}
            className="w-full"
            id="llcIdNumber"
            errorMessage={t('This field is required')}
            value={companyInfo?.llcIdNumber}
            validateCaller={validateCaller}
            onChange={(value) => handleFormChange('llcIdNumber', value)}
          />
        </div>
        <div>
          <FormFieldText
            isFixedValue={readonly}
            label={t('LLC EIN')}
            className="w-full"
            id="llcEin"
            errorMessage={t('This field is required')}
            value={companyInfo?.llcEin}
            validateCaller={validateCaller}
            onChange={(value) => handleFormChange('llcEin', value)}
          />
        </div>
        <div>
          <FormFieldSelect
            isFixedValue={readonly}
            optionInfos={APPLIED_STATES}
            label={t('Applied States')}
            isRequired
            className="w-full"
            id="entity"
            value={companyInfo?.appliedStates}
            validateCaller={validateCaller}
            onChange={(value) => handleFormChange('appliedStates', value)}
          />
        </div>

        <div className="min-w-full col-span-1 xl:col-span-3">
          <FormFieldTextArea
            isRequired
            isFixedValue={readonly}
            label={t('Company Description')}
            className="w-full"
            id="description"
            value={companyInfo?.description}
            validateCaller={{}}
            onChange={(value) => handleFormChange('description', value)}
          />
        </div>
      </>
    );
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-4 gap-y-6 justify-start">
      <div>
        <FormFieldText
          isRequired
          label={t('Company Name')}
          isFixedValue={readonly}
          className="w-full"
          id="companyName"
          errorMessage={t('This field is required')}
          validateCaller={validateCaller}
          value={companyInfo?.companyName || ''}
          onChange={(value) => handleFormChange('companyName', value)}
        />
      </div>
      <div>
        <FormFieldText
          label={t('Company Name 2')}
          isRequired
          isFixedValue={readonly}
          className="w-full"
          id="companyName2"
          errorMessage={t('This field is required')}
          validateCaller={validateCaller}
          value={companyInfo?.companyName2 || ''}
          onChange={(value) => handleFormChange('companyName2', value)}
        />
      </div>
      <div>
        <FormFieldText
          label={t('Company Name 3')}
          isRequired
          isFixedValue={readonly}
          className="w-full"
          id="companyName3"
          errorMessage={t('This field is required')}
          validateCaller={validateCaller}
          value={companyInfo?.companyName3 || ''}
          onChange={(value) => handleFormChange('companyName3', value)}
        />
      </div>

      <div>
        <FormFieldSelect
          isFixedValue={readonly}
          optionInfos={
            isUS
              ? ENTITY_ENDING_INFOS_US
              : isHK
              ? ENTITY_ENDING_HK
              : ENTITY_ENDING_SING
          }
          label={t('Entity Ending')}
          isRequired
          className="w-full"
          id="entity"
          value={companyInfo?.entityEnding}
          validateCaller={validateCaller}
          onChange={(value) => handleFormChange('entityEnding', value)}
        />
      </div>
      <div className="flex justify-between gap-2">
        <FormFieldNumber
          label={t('Capital')}
          isFixedValue={readonly}
          className="w-full"
          isRequired
          id="capital"
          errorMessage={t('This field is required')}
          validateCaller={validateCaller}
          value={companyInfo?.capital}
          onChange={(value) => handleFormChange('capital', Number(value))}
        />
        <div className="w-1/2">
          <FormFieldSelect
            isFixedValue={readonly}
            optionInfos={CURRENCY}
            isRequired
            label={t('Currency')}
            id="entity"
            value={companyInfo?.currency}
            validateCaller={validateCaller}
            onChange={(value) => handleFormChange('currency', value)}
          />
        </div>
      </div>
      <div className="flex gap-2">
        <div>
          <FormDateTime
            label={isUS ? t('Formation Date') : t('Incorporation Date')}
            value={
              companyInfo?.formationDate
                ? dayjs(companyInfo?.formationDate)
                : null
            }
            onChange={(value) =>
              handleFormChange('formationDate', value?.format() ?? '')
            }
          />
        </div>
        <div>
          <FormDateTime
            label={t('Financial Year End')}
            value={
              companyInfo?.financialYearEnd
                ? dayjs(companyInfo?.financialYearEnd)
                : null
            }
            onChange={(value) =>
              handleFormChange('financialYearEnd', value?.format() ?? '')
            }
          />
        </div>
      </div>
      <div className="col-span-1">
        <FormFieldSelect
          isFixedValue={readonly}
          optionInfos={INDUSTRY_INFOS}
          isRequired
          label={t('Primary Business Activity')}
          className="w-full"
          id="industry"
          value={companyInfo?.industry}
          validateCaller={validateCaller}
          onChange={(value) => handleFormChange('industry', value)}
        />
      </div>
      <div className="col-span-1">
        <FormFieldSelect
          isFixedValue={readonly}
          optionInfos={INDUSTRY_INFOS}
          label={t('Second Business Activity')}
          className="w-full"
          id="industry2"
          value={companyInfo?.industry2}
          validateCaller={validateCaller}
          onChange={(value) => handleFormChange('industry2', value)}
        />
      </div>
      <div>
        <FormFieldText
          isFixedValue={readonly}
          label={t('Website')}
          className="w-full"
          id="website"
          errorMessage={t('This field is required')}
          value={companyInfo?.website}
          validateCaller={validateCaller}
          onChange={(value) => handleFormChange('website', value)}
        />
      </div>
      {isUS ? renderUSInfo() : renderOtherInfo()}
    </div>
  );
}
