import { useEffect, useRef, useState } from 'react';
import { IconEyesOpen, IconUpload, IconX } from './icons';
import { getFile, useApiGetFileName } from '../api/upload';
import { DialogContainer } from './DialogContainer';
import MediaPreview from './MediaPreview';

interface Props {
  onChange?: (file?: File) => void;
  accept?: string;
  label?: string;
  fileName?: string;
  disabled?: boolean;
  maxSize?: number;
  setIsPDF?: (value: boolean) => void;
  canDeleteFile?: boolean;
  isFullSize?: boolean;
  hiddenUploadBtn?: boolean;
}

export default function InputFile({
  onChange,
  accept = '*',
  label = 'Upload',
  fileName,
  disabled = false,
  maxSize = 40,
  setIsPDF,
  canDeleteFile = true,
  isFullSize = false,
  hiddenUploadBtn = false,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const { data } = useApiGetFileName([fileName as string], !!fileName);

  useEffect(() => {
    if (!data) return;
    const isPDF = data[0].fileType.toLowerCase().includes('pdf');
    setIsPDF && setIsPDF(isPDF);
  }, [data]);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileFromLocal = event.target.files?.[0];
    event.target.value = '';

    if (fileFromLocal && fileFromLocal.size > maxSize * 1024 * 1024) {
      alert(`File phải nhỏ hơn ${maxSize}MB `);
    } else {
      onChange && onChange(fileFromLocal);
    }
  };

  const onClearFile = () => {
    onChange && onChange();
  };

  const handleUpload = () => {
    fileInputRef.current?.click();
  };

  const handlePreview = async () => {
    if (!data) return;
    const blob = await getFile(data[0].id, { download: false });
    blob && setPreviewUrl(URL.createObjectURL(blob));
  };

  return (
    <div
      className={` w-full ${
        !isFullSize ? 'flex justify-center items-center' : ''
      }`}
    >
      <input
        className="hidden"
        type="file"
        accept={accept}
        ref={fileInputRef}
        onChange={onFileChange}
        disabled={disabled}
      />
      {!fileName && canDeleteFile && !hiddenUploadBtn && (
        <button
          type="button"
          onClick={handleUpload}
          className={
            'flex gap-md bg-primary px-6 py-4 rounded-lg text-white disabled:opacity-50 disabled:cursor-not-allowed'
          }
          disabled={disabled}
        >
          <div>{<IconUpload />}</div>
          <div>{label}</div>
        </button>
      )}

      {data && (
        <>
          <div
            className={`flex gap-md justify-between  p-2  items-center ${
              !isFullSize
                ? 'w-80 border border-solid border-surface rounded-lg'
                : ''
            }`}
          >
            <div className="font-bold max-w-60 truncate">
              {data[0].fileName}
            </div>
            <div className="flex items-center">
              {canDeleteFile && (
                <button
                  className="font-bold hover:bg-danger/20 text-danger p-1 rounded-lg mr-2"
                  onClick={onClearFile}
                >
                  <IconX />
                </button>
              )}
              <IconEyesOpen
                className=" cursor-pointer"
                onClick={handlePreview}
              />
            </div>
          </div>
        </>
      )}
      {previewUrl && (
        <DialogContainer
          onClose={() => {
            setPreviewUrl('');
          }}
          isCloseOnClickOverlay
          isAutoSize
          panelClassName={'max-h-[95vh] overflow-y-auto'}
        >
          <MediaPreview
            urlPreview={previewUrl}
            fileType={data?.[0].fileType}
            onClose={() => setPreviewUrl('')}
            fileId={data?.[0].id}
          />
        </DialogContainer>
      )}
    </div>
  );
}
