import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridRowEventLookup,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DialogSuccessFullscreen } from '../components/DialogFormStatusFullscreen';
import {
  useApiApproveOrder,
  useApiGetOrders,
  useApiRejectOrder,
} from '../hooks/api/order-payment';
import { generateFormatDate } from '../services-ui/date';
import { useValidateCaller } from '../hooks-ui/useValidateCaller';
import { FormFieldText } from '../components/FormFieldText';
import styled from '@emotion/styled';
import { ApiOrder } from '../api/types';
import ConfirmDialog from '../components/ConfirmDialog';
import { StatusBadge } from '../components/StatusBadge';
import { Status } from '../constants/StatusBadge';
import ButtonCs from '../components/ButtonCs';
import { handleExportData } from '../services-base/api';
import { TAB_EXPORT } from '../constants/global';

const Table = styled(DataGrid)`
  .MuiDataGrid-cell {
    overflow: visible !important;
  }
` as typeof DataGrid;

type Props = {};

export function OrderPaymentContent(props: Props) {
  // TODO: update api
  const translation = useTranslation();
  const { validateCaller } = useValidateCaller();

  const [orderId, setOrderId] = useState<string>('');
  const [customerName, setCustomerName] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const currentOrderRef = useRef<{ action: string; id: number }>({
    action: '',
    id: 0,
  });
  const { data, refetch, isLoading } = useApiGetOrders({
    page: paginationModel.page,
    size: paginationModel.pageSize,
    orderId,
    customerName,
  });

  const orders = data?.content || [];
  const orderCount = data?.totalElements;

  const { mutateAsync: approveOrder } = useApiApproveOrder({
    onSuccess: () => {
      setShowSuccessDialog(true);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const { mutateAsync: rejectOrder } = useApiRejectOrder({
    onSuccess: () => {
      setShowSuccessDialog(true);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleClickSearch = () => {
    refetch();
  };

  const handleReset = () => {
    setOrderId('');
    setCustomerName('');
    setTimeout(refetch);
  };

  function handleRowClick(params: GridRowEventLookup['rowClick']['params']) {}

  const handleOrder = async () => {
    if (currentOrderRef.current.action === 'Approve') {
      await approveOrder(currentOrderRef.current.id);
    } else {
      await rejectOrder(currentOrderRef.current.id);
    }
    setIsOpen(false);
    await refetch();
  };

  const handleExportDataService = () =>
    handleExportData(TAB_EXPORT.ORDER_PAYMENT);

  // TODO: add i18n for columns
  const orderPaymentColumns: GridColDef<NonNullable<typeof orders>[number]>[] =
    [
      {
        field: 'rowIndex',
        headerName: 'No.',
        width: 50,
        valueGetter: (params) =>
          params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
      },
      { field: 'orderId', headerName: 'Order ID', width: 150 },
      {
        field: 'actions',
        headerName: 'Options',
        sortable: false,
        type: 'string',
        width: 200,
        renderCell: (params) => {
          const status = params.row.statusPayment;
          const isDisabled = status !== 'Pending';
          const classDisabled =
            '!bg-[rgba(0,_0,_0,_0.12)] !text-[rgba(0,_0,_0,_0.26)] pointer-events-none';
          return (
            <div className="flex flex-row gap-3">
              <button
                onClick={() => {
                  currentOrderRef.current.action = 'Reject';
                  currentOrderRef.current.id = params.row.transitionId;
                  setIsOpen(true);
                }}
                className={`py-2 px-3 rounded-lg  bg-[#fa6673] hover:bg-[#f54554] cursor-pointer text-[#fff] ${
                  isDisabled ? classDisabled : ''
                }`}
                disabled={isDisabled}
              >
                {translation.t('Reject')}
              </button>
              <button
                onClick={() => {
                  currentOrderRef.current.action = 'Approve';
                  currentOrderRef.current.id = params.row.transitionId;
                  setIsOpen(true);
                }}
                disabled={isDisabled}
                className={`py-2 px-3 rounded-lg cursor-pointer bg-green-100 hover:bg-green-200 text-success ${
                  isDisabled ? classDisabled : ''
                }`}
              >
                {translation.t('Approve')}
              </button>
            </div>
          );
        },
      },
      { field: 'transitionId', headerName: 'Transaction ID', width: 150 },
      {
        field: 'statusPayment',
        headerName: 'Status',
        sortable: true,
        type: 'string',
        width: 100,
        cellClassName: (params: GridCellParams) => {
          const status = params.row.statusPayment;
          if (status === 'Approved') {
            return 'text-green-500';
          }

          if (status === 'Pending') {
            return 'text-purple-500';
          }

          if (status === 'Rejected') {
            return 'text-[#f54554]';
          }
          return '';
        },
        renderCell: (params) => {
          const status = params.row.statusPayment;
          return <StatusBadge status={status as Status}></StatusBadge>;
        },
      },
      {
        field: 'name',
        headerName: 'Customer Name',
        description: 'This column has a value getter and is not sortable.',
        sortable: true,
        width: 160,
        valueGetter: (params) =>
          `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      },
      {
        field: 'serviceName',
        headerName: 'Service Name',
        sortable: false,
        type: 'string',
        width: 200,
        renderCell: (params) => {
          return (
            <div className="w-full relative group">
              <div
                className={
                  'absolute hidden group-hover:block bg-white rounded p-3 z-50 shadow ' +
                  (params.api.getRowIndexRelativeToVisibleRows(params.id) >
                  (orderCount || 0) / 2
                    ? 'bottom-6'
                    : 'top-6')
                }
              >
                <table className="table-auto border-collapse border border-slate-500 rounded">
                  <thead>
                    <tr>
                      <th className="border border-slate-300 p-2">
                        {translation.t('Service ID')}
                      </th>
                      <th className="border border-slate-300 p-2">
                        {translation.t('Service Name')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {params.row.paidService.map(
                      ({ paidServiceId, serviceName }) => (
                        <tr key={paidServiceId}>
                          <td className="border border-slate-300 p-2">
                            {paidServiceId}
                          </td>
                          <td className="border border-slate-300 p-2">
                            {serviceName}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>

              <div className="line-clamp-1 overflow-hidden">
                {params.row.paidService
                  .map((item) => item.serviceName)
                  .join(', ')}
              </div>
            </div>
          );
        },
      },
      {
        field: 'paymentMethod',
        headerName: 'Payment Method',
        sortable: true,
        width: 160,
      },
      {
        field: 'amount',
        headerName: 'Amount in USD ($)',
        sortable: true,
        type: 'string',
        width: 150,
        renderCell: ({ row }) => {
          const totalPricePerCycle = row.paidService.reduce(
            (acc, cur) => acc + cur.pricePerCycle,
            0,
          );
          return (
            <div className="text-right w-full pr-4">{totalPricePerCycle}$</div>
          );
        },
      },
      {
        field: 'amountOther',
        headerName: 'Amount in other currency',
        sortable: true,
        type: 'string',
        width: 150,
        renderCell: ({ row }) => {
          const priceOther = row.currency ? row.amountOtherCurrency + ' ' + row.currency : '';
          return <div className="text-right w-full pr-4">{priceOther}</div>;
        },
      },
      {
        field: 'nation',
        headerName: 'Nation',
        sortable: true,
        width: 160,
      },
      {
        field: 'phone',
        headerName: 'Phone number',
        sortable: true,
        type: 'string',
        width: 120,
        renderCell: ({ row }) => {
          return <div>{row.codePhone + ' ' + row.phone}</div>;
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        type: 'string',
        width: 200,
      },
      {
        field: 'createdAt',
        headerName: 'Created on',
        sortable: true,
        type: 'string',
        width: 120,
        valueGetter: (params) => {
          return generateFormatDate(new Date(params.row.createdAt));
        },
      },
      {
        field: 'updatedBy',
        headerName: 'Updated By',
        sortable: false,
        type: 'string',
        width: 200,
        valueGetter: (params: GridValueGetterParams<any>) => {
          return params.row.updatedBy;
        },
      },
    ];

  return (
    <>
      <div className={'w-full grow flex flex-col p-3'}>
        <div
          className={
            'flex flex-col grow overflow-x-hidden overflow-auto bg-white rounded justify-start items-center py-6 px-4 sm:px-8'
          }
        >
          <p className={'text-h4 w-full text-start mb-6'}>
            {translation.t('Order Payment Management')}
          </p>
          <div
            className={
              'w-full flex flex-row justify-between items-center gap-10 mb-4'
            }
          >
            <div
              className={
                'w-full flex flex-row justify-start items-end gap-4 mb-4'
              }
            >
              <FormFieldText
                label={translation.t('Customer Name')}
                id="customerName"
                validateCaller={validateCaller}
                onChange={setCustomerName}
                value={customerName}
                placeholder={translation.t('Input customer name')}
              />
              <FormFieldText
                label={translation.t('Order ID')}
                id="orderId"
                validateCaller={validateCaller}
                onChange={setOrderId}
                value={orderId}
                placeholder={translation.t('Input order ID')}
              />
              <div className="flex gap-2">
                <ButtonCs
                  type="button"
                  className="bg-transparent border border-surface text-primary"
                  onClick={handleReset}
                  isLoading={isLoading}
                >
                  {translation.t('Reset')}
                </ButtonCs>
                <ButtonCs
                  isLoading={isLoading}
                  type="submit"
                  onClick={handleClickSearch}
                >
                  {translation.t('Apply')}
                </ButtonCs>
              </div>
            </div>
            <ButtonCs
              type="submit"
              className="h-[40px]"
              onClick={handleExportDataService}
            >
              {translation.t('Export')}
            </ButtonCs>
          </div>
          <div className={'w-full grow'}>
            <Table
              paginationMode="server"
              rows={orders}
              columns={orderPaymentColumns}
              initialState={{
                columns: { columnVisibilityModel: { transitionId: false } },
              }}
              sx={{
                height: 'calc(100vh - 232px)',
              }}
              pageSizeOptions={[25]}
              rowCount={orderCount || 0}
              paginationModel={paginationModel}
              onPaginationModelChange={(model) => setPaginationModel(model)}
              onRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>

      {showSuccessDialog && (
        <DialogSuccessFullscreen
          title={`Order ${
            currentOrderRef.current.action === 'Approve'
              ? 'Approved'
              : 'Rejected'
          }`}
          onClose={() => setShowSuccessDialog(false)}
        />
      )}
      <ConfirmDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAccept={handleOrder}
        title="Confirm Infomation"
        contentMsg={translation.t('This action cannot be undone. Continue?')}
      />
    </>
  );
}
