import { useQuery } from '@tanstack/react-query';
import { KeyFactory } from '../../services-base/key-factory';
import { RequestManagementProps } from './request';
import { callApiGetQuotationList } from '../../api/quotationRequest';

export const useApiGetListQuotation = (parmas: RequestManagementProps) => {
  return useQuery({
    queryKey: KeyFactory.getQuotation(parmas.page, parmas.size),
    queryFn: () => callApiGetQuotationList(parmas),
  });
};
