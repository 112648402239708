export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const addThousandSeparator = (num?: string): string => {
  const parts = num?.split('.');
  const integerPart = parts?.[0];
  const decimalPart = parts?.[1];
  const formattedInteger = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${formattedInteger}${decimalPart ? '.' + decimalPart : ''}`;
};
