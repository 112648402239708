import { useTranslation } from 'react-i18next';
import UploadImage from '../../assets/images/UploadIcon.png';
import {
  deleteFileContract,
  getFile,
  uploadFile,
  useApiGetFileName,
} from '../../api/upload';
import { toast } from 'react-toastify';
import { DeleteIcon, DownloadIcon, IconUpload } from '../icons';
import { Service } from '../../types/service';

interface IProps {
  service: Service | null;
  refetch?: () => void;
  onClose: () => void;
}

const ContractDialog = ({ service, refetch, onClose }: IProps) => {
  const { t } = useTranslation();

  const listIds = service?.contractFile?.split(',') ?? [];
  const { data } = useApiGetFileName(listIds, !!listIds.length);

  const uploadContractFile = async (files: FileList | null) => {
    if (files && files?.length > 0) {
      try {
        const res = await uploadFile(
          files,
          { id: service?.id?.toString() ?? '' },
          '/api/file/upload-final-contract',
        );
        if (res) {
          toast.success(t('Upload contract file successfully'));
          refetch && refetch();
        }
      } catch (error) {
        toast.error(String(error));
      }
    }
  };
  return (
    <div className="flex flex-col gap-6 p-6 ">
      <p className="text-2xl font-bold font-siz text-center">
        {t('Upload your final contract')}
      </p>
      <div className="flex justify-center items-center">
        <img src={UploadImage} alt="" width={160} />
      </div>
      <div className="">
        <label
          htmlFor="upload"
          className="flex justify-center gap-3 text-primary rounded-lg w-full border border-solid border-primary py-4 font-bold cursor-pointer"
        >
          <IconUpload />
          {t('Upload')}
          <input
            id="upload"
            type="file"
            className={'hidden'}
            onChange={(e) => {
              uploadContractFile(e.target.files);
            }}
            multiple
          />
        </label>
      </div>
      {data && data?.length > 0 && (
        <div className="flex flex-col border border-solid border-surface rounded-lg max-h-[170px] overflow-auto">
          {data?.map((file) => (
            <div className="flex justify-between py-4 px-3" key={file.id}>
              <div className="font-bold text-ellipsis overflow-hidden">
                {file.fileName}
              </div>
              <div className="flex gap-2 items-center justify-center">
                {data?.length > 1 && (
                  <DeleteIcon
                    className=" w-6 h-6 cursor-pointer"
                    onClick={async () => {
                      service &&
                        (await deleteFileContract(file.id, service?.id));
                      refetch && refetch();
                    }}
                  />
                )}
                <DownloadIcon
                  className=" cursor-pointer"
                  onClick={() => {
                    if (!file.id) return;
                    getFile(file.id);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex justify-center">
        <button
          className="px-6 font-bold bg-primary text-white rounded-lg py-3 flex gap-1 w-20"
          onClick={onClose}
        >
          {t('Back')}
        </button>
      </div>
    </div>
  );
};

export default ContractDialog;
