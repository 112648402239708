import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogFailureFullscreen,
  DialogSuccessFullscreen,
} from '../../DialogFormStatusFullscreen';
import {
  useApiGetMyCompanyDetail,
  useApiUpdateMyCompanyDetail,
} from '../../../hooks-api/useMyCompany';
import {
  validateCompanyInfo,
  validateMailingAddress,
  validateOwnersInfo,
} from '../../../services-business/myCompany';
import { CompanyInformationTab } from './CompanyInformationTab';
import { DocumentTab } from './DocumentTab';
import { MailingAddressTab } from './MailingAddressTab';
import { OwnerInformationTab } from './OwnerInformationTab';
import {
  CompanyInformation,
  Document,
  MailingAddress,
  OwnerInformation,
} from '../../../api/types';
import ButtonCs from '../../ButtonCs';
import { IconX } from '../../icons';

const TABS = [
  'Company Information',
  'Shareholder',
  'Owner information',
  'Responsible Party',
  'Director',
  'Registered office address',
  // 'Document',
] as const;

type Props = {
  id: number;
  nation?: string;
  isCancel: () => void;
  needUpdate?: boolean;
  reloadList?: () => void;
};

export function MyCompanyDetailPage({
  id,
  nation,
  isCancel,
  needUpdate,
  reloadList,
}: Props) {
  const { t } = useTranslation();
  const { data, status, refetch } = useApiGetMyCompanyDetail(id);
  const [activeTab, setActiveTab] = useState<(typeof TABS)[number]>(TABS[0]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [error, setError] = useState<string | false>(false);
  const [companyInfo, setCompanyInfo] = useState<Partial<CompanyInformation>>(
    {},
  );
  const [owners, setOwners] = useState<Partial<OwnerInformation>[]>([]);
  const [responseParty, setResponseParty] = useState<
    Partial<OwnerInformation>[]
  >([]);
  const [mailingAddress, setMailingAddress] = useState<Partial<MailingAddress>>(
    {},
  );
  const [documents, setDocuments] = useState<Document[]>([]);

  const { mutateAsync: saveMyCompany } = useApiUpdateMyCompanyDetail({
    onError: (error) => setError(String(error)),
  });
  const isUS = nation === 'United States';
  const tabList = useMemo(() => {
    const result = TABS.filter((tab) => {
      if (isUS) return !['Shareholder', 'Director'].includes(tab);
      else return !['Owner information', 'Responsible Party'].includes(tab);
    });
    return result;
  }, []);

  useEffect(() => {
    if (status === 'success') {
      setCompanyInfo(data.companyInfo);
      setOwners(data.owners);
      setResponseParty(data.director);
      setMailingAddress(data.mailingAddress);
      setDocuments(data.documents);
    }
  }, [status, data]);

  useEffect(() => {
    if (needUpdate) refetch();
  }, [id]);

  const handleCheckDisabled = () => {
    const currentIndex = tabList.indexOf(activeTab);
    switch (currentIndex) {
      case 0:
        return validateCompanyInfo(companyInfo, isUS);
      case 1:
        return validateOwnersInfo(owners);
      case 2:
        return validateOwnersInfo(responseParty, true);
      default:
        return true;
    }
  };

  const handleSave = async () => {
    try {
      setError(false);
      await saveMyCompany({
        id: id,
        companyDescription: companyInfo.description ?? '',
        companyName: companyInfo.companyName ?? '',
        companyName2: companyInfo.companyName2,
        companyName3: companyInfo.companyName3,
        capital: companyInfo.capital,
        currency: companyInfo.currency,
        entityEnding: companyInfo.entityEnding ?? '',
        formationDate: companyInfo.formationDate,
        companyNumber: companyInfo.companyNumber ?? '',
        appliedStates: companyInfo.appliedStates ?? '',
        website: companyInfo.website ?? '',
        llcEin: companyInfo.llcEin ?? '',
        llcIdNumber: companyInfo.llcIdNumber ?? '',
        industry: companyInfo.industry ?? '',
        industry2: companyInfo.industry2 ?? '',
        incorporationDate: companyInfo.incorporationDate ?? '',
        financialYearEnd: companyInfo.financialYearEnd ?? '',
        mailingAddress: mailingAddress.address,
        mailingCity: mailingAddress.city,
        mailingCountry: mailingAddress.country,
        mailingState: mailingAddress.state ?? '',
        mailingZipCode: mailingAddress.zipCode,
        isUsingDrmsAddress: Boolean(mailingAddress?.isUsingDrmsAddress),
        owner: owners.map((item) => ({
          id: item.id,
          nationality: item.nationality,
          passportImagesIdCard: item.passportImagesIdCard,
          shareholderExpiryDate: item.shareholderExpiryDate,
          zipCode: item.zipCode,
          companyName: item.companyName ?? '',
          firstName: item.firstName ?? '',
          lastName: item.lastName ?? '',
          ownerShip: item?.ownership?.toString(),
          contactNumber: item.contactNumber,
          idNumber: item?.idNumber,
          email: item?.email,
          businessNumber: item?.businessNumber,
          authorizedPerson: item.authorizedPerson,
          registeredAddress: item?.registeredAddress,
          dateOfBirth: item?.dateOfBirth,
          document: item.document,
          ownerType: item.type === 'Company' ? 1 : 2,
        })),
        director: responseParty.map((item) => ({
          id: item.id,
          nationality: item.nationality,
          passportImagesIdCard: item.passportImagesIdCard,
          shareholderExpiryDate: item.shareholderExpiryDate,
          zipCode: item.zipCode,
          companyName: item.companyName ?? '',
          firstName: item.firstName ?? '',
          lastName: item.lastName ?? '',
          ownerShip: item?.ownership?.toString(),
          contactNumber: item.contactNumber,
          idNumber: item?.idNumber,
          email: item?.email,
          dateOfBirth: item?.dateOfBirth,
          businessNumber: item?.businessNumber,
          authorizedPerson: item.authorizedPerson,
          registeredAddress: item?.registeredAddress,
          document: item.document,
          ownerType: 2,
        })),
        document: documents.map((item) => ({ document: item.name })),
      });
      setShowSuccessDialog(true);
      refetch();
      isCancel();
      reloadList && reloadList();
    } catch (error) {
      setError(error as string);
    }
  };

  return (
    <>
      <div className="bg-white w-full flex flex-col border border-l border-stroke min-h-[70vh]">
        <div className="grow p-10 overflow-y-auto flex flex-col">
          <div className="flex justify-between items-center mb-12 text-lg font-semibold relative after:absolute after:w-16 after:h-0.5 after:bg-primary after:left-0 after:-bottom-1">
            {t('Company Detail')}
            <IconX
              className="cursor-pointer -mr-4 -mt-10 size-5"
              onClick={isCancel}
            />
          </div>

          <div className="rounded-lg p-1 border border-solid border-surface mb-12 overflow-auto relative h-14">
            <div className="flex justify-evenly w-full h-full">
              {tabList.map((tab) => (
                <div
                  key={tab}
                  className={clsx(
                    'inline-flex h-12 justify-center items-center grow font-bold rounded-lg transition-all w-max line-clamp-1 min-w-52',
                    activeTab === tab
                      ? 'bg-primary_25 text-black'
                      : 'text-surface',
                  )}
                >
                  {t(tab)}
                </div>
              ))}
            </div>
          </div>

          <div className="grow border border-solid border-surface rounded-lg p-6 overflow-auto h-[55vh]">
            {activeTab === 'Company Information' && (
              <CompanyInformationTab
                readonly={false}
                nation={nation}
                companyInfo={companyInfo}
                onChange={setCompanyInfo}
              />
            )}
            {['Owner information', 'Shareholder'].includes(activeTab) && (
              <OwnerInformationTab
                readonly={false}
                owners={owners}
                onChange={setOwners}
                nation={nation}
                shareData={responseParty}
                isUS={isUS}
              />
            )}
            {['Responsible Party', 'Director'].includes(activeTab) && (
              <OwnerInformationTab
                readonly={false}
                owners={responseParty}
                shareData={owners}
                nation={nation}
                onChange={setResponseParty}
                isUS={isUS}
                isDirector={true}
              />
            )}
            {activeTab === 'Registered office address' && (
              <MailingAddressTab
                readonly={false}
                mailingAddress={mailingAddress}
                onChange={setMailingAddress}
              />
            )}
            {/* {activeTab === 'Document' && (
              <DocumentTab
                readonly={false}
                documents={documents}
                onChange={setDocuments}
              />
            )} */}
          </div>
        </div>

        <div className="h-20 shrink-0 border-t border-solid border-stroke flex justify-end gap-2 items-center px-8">
          <ButtonCs
            className="border border-surface text-primary bg-transparent"
            onClick={() => {
              const currentIndex = tabList.indexOf(activeTab);
              setActiveTab(tabList[currentIndex - 1]);
            }}
            disabled={activeTab === 'Company Information'}
          >
            {t('Previous')}
          </ButtonCs>
          {activeTab !== 'Registered office address' && (
            <ButtonCs
              onClick={() => {
                const currentIndex = tabList.indexOf(activeTab);
                setActiveTab(tabList[currentIndex + 1]);
              }}
              disabled={handleCheckDisabled()}
            >
              {t('Next')}
            </ButtonCs>
          )}
          {activeTab === 'Registered office address' && (
            <ButtonCs
              onClick={() => {
                handleSave();
              }}
              disabled={validateMailingAddress(mailingAddress)}
            >
              {t('Submit')}
            </ButtonCs>
          )}
        </div>
      </div>

      {showSuccessDialog && (
        <DialogSuccessFullscreen
          onClose={() => setShowSuccessDialog(false)}
          title="Company information updated"
          actionElement={
            <ButtonCs onClick={() => setShowSuccessDialog(false)}>
              Close
            </ButtonCs>
          }
        />
      )}

      {error && (
        <DialogFailureFullscreen
          title={error}
          onClose={() => setError(false)}
          actionElement={
            <ButtonCs onClick={() => setError(false)}>Close</ButtonCs>
          }
        />
      )}
    </>
  );
}
