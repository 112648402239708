import { useMemo, useState } from 'react';
import { FormFieldText } from '../components/FormFieldText';
import { useTranslation } from 'react-i18next';
import ButtonCs from '../components/ButtonCs';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { IconPen } from '../components/icons';
import { DialogContainer } from '../components/DialogContainer';
import { useApiGetRequestManagement } from '../hooks/api/request';
import FormDateTime from '../components/FormDateTime';
import dayjs from 'dayjs';
import { generateFormatDate } from '../services-ui/date';
import {
  addOnColumns,
  coreColumns,
  referColumns,
  transferColumns,
} from '../utils/columnRequest';
import { RequestManagementResponese } from '../api/types';
import DialogRequestDetail from '../components/DialogRequestDetail';
import { StatusBadge } from '../components/StatusBadge';
import { Status } from '../constants/StatusBadge';

const DEFAULT_SEARCH = {
  searchStr: '',
  fromDate: '',
  toDate: '',
};

interface IProps {
  path: string;
}

const RequestManagement = ({ path }: IProps) => {
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });
  const [showDetailRequest, setShowDetailRequest] = useState(false);
  const [selected, setSelected] = useState<RequestManagementResponese>({});

  const [dataSearch, setDataSearch] = useState<{
    [key in string]: string;
  }>(DEFAULT_SEARCH);
  const commonCulumn: GridColDef[] = [
    {
      field: 'customerName',
      headerName: 'Customer Name',
      sortable: true,
      type: 'string',
      width: 180,
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      width: 180,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      sortable: true,
      type: 'string',
      width: 180,
    },

    {
      field: 'createdAt',
      headerName: 'Requested on',
      sortable: true,
      type: 'string',
      width: 180,
      valueGetter: (params: GridValueGetterParams<any>) => {
        return generateFormatDate(new Date(params.row.createdAt));
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      sortable: true,
      renderCell: (params) => {
        const status = params.row.status;
        return <StatusBadge status={status as Status}></StatusBadge>;
      },
    },
    {
      field: 'pic',
      headerName: 'Person incharge',
      sortable: true,
      type: 'string',
      width: 150,
    },
    {
      field: 'Option',
      headerName: 'Option',
      sortable: false,
      type: 'string',
      width: 150,
      renderCell: (row) => (
        <button
          className=" rounded bg-primary px-10 py-1 svg-white"
          onClick={() => {
            setShowDetailRequest(true);
            setSelected(row.row);
          }}
        >
          <IconPen className="fill-white" />
        </button>
      ),
    },
  ];

  const dataConfig = useMemo(() => {
    setDataSearch(DEFAULT_SEARCH);
    switch (path) {
      case '/requestManagement/transfer':
        return {
          id: 1,
          path: 'findTransferRequest',
          header: transferColumns.concat(commonCulumn),
          titlePopup: 'Transfer Service Request Detail',
          title: 'Transfer Services Request List',
          pathUpdate: 'updateTransfer',
        };
      case '/requestManagement/refer':
        return {
          id: 2,
          path: 'findReferRequest',
          header: referColumns.concat(commonCulumn),
          title: 'Refer Services Request List',
          titlePopup: 'Refer Service Request Detail',
          pathUpdate: 'updateRefer',
        };
      case '/requestManagement/addOn':
        return {
          id: 3,
          path: 'findAdditionalRequest',
          header: addOnColumns.concat(commonCulumn),
          title: 'Add-on Services Request List',
          titlePopup: 'Add-on Service Request Detail',
          pathUpdate: 'updateAdditional',
        };
      case '/requestManagement/core':
        return {
          id: 4,
          path: 'findPricingRequest',
          header: coreColumns.concat(commonCulumn),
          title: 'Core Services Request List',
          titlePopup: 'Core Service Request Detail',
          pathUpdate: 'updatePricing',
        };
      default:
        return {
          id: 5,
          path: '',
          header: transferColumns.concat(commonCulumn),
          title: 'Add-on Services Request List',
          titlePopup: 'Add-on Service Request Detail',
          pathUpdate: 'updateTransfer',
        };
    }
  }, [path]);

  const { data, refetch } = useApiGetRequestManagement(
    {
      page: paginationModel.page,
      size: paginationModel.pageSize,
      searchStr: dataSearch.searchStr,
      fromDate: dataSearch.fromDate,
      toDate: dataSearch.toDate,
    },
    dataConfig.path,
  );

  const handleReset = () => {
    setDataSearch(DEFAULT_SEARCH);
    setTimeout(refetch);
  };

  const handleSearch = () => {
    refetch();
  };
  return (
    <div className={'w-full grow flex flex-col p-3'}>
      <div
        className={
          'flex flex-col grow overflow-auto bg-white rounded justify-start items-center py-6 px-4 sm:px-8'
        }
      >
        <div className={'text-h4 w-full text-start mb-6'}>
          {dataConfig.title}
        </div>
        <div
          className={'w-full grow'}
          key={data?.content?.map((value: any) => value.id).join('_')}
        >
          <div
            className={
              'w-full flex flex-row justify-between items-center gap-10 mb-4'
            }
          >
            <div
              className={
                'w-full flex flex-row justify-start items-end gap-4 mb-4'
              }
            >
              <FormFieldText
                label={t('Custom Search')}
                id="companyName"
                validateCaller={{}}
                onChange={(e) => setDataSearch({ ...dataSearch, searchStr: e })}
                value={dataSearch.searchStr}
                placeholder={t('Input search value')}
              />
              <div className="">
                <FormDateTime
                  label={t('From')}
                  value={
                    dataSearch?.fromDate ? dayjs(dataSearch?.fromDate) : null
                  }
                  onChange={(value) =>
                    setDataSearch({
                      ...dataSearch,
                      fromDate: value?.format() ?? '',
                    })
                  }
                />
              </div>
              <div>
                <FormDateTime
                  label={t('To')}
                  value={dataSearch?.toDate ? dayjs(dataSearch?.toDate) : null}
                  onChange={(value) =>
                    setDataSearch({
                      ...dataSearch,
                      toDate: value?.format() ?? '',
                    })
                  }
                />
              </div>
              <div className="flex gap-2">
                <ButtonCs
                  type="button"
                  className="bg-transparent border border-surface text-primary"
                  onClick={handleReset}
                >
                  {t('Reset')}
                </ButtonCs>
                <ButtonCs type="submit" onClick={handleSearch}>
                  {t('Apply')}
                </ButtonCs>
              </div>
            </div>
          </div>
          <DataGrid
            paginationMode="server"
            rows={data?.content || []}
            columns={dataConfig.header}
            sx={{
              height: 'calc(100vh - 250px)',
            }}
            pageSizeOptions={[25]}
            rowCount={data?.totalElements || 0}
            getRowId={(row) => row.id}
            paginationModel={paginationModel}
            onPaginationModelChange={(model) => setPaginationModel(model)}
          />
        </div>
      </div>
      {showDetailRequest && (
        <DialogContainer
          onClose={() => {
            setShowDetailRequest(false);
          }}
          isCloseOnClickOverlay
          isFullSize
          isAutoSize
          panelClassName={'max-w-[1200px]'}
        >
          <DialogRequestDetail
            data={selected}
            refetch={refetch}
            onClose={() => setShowDetailRequest(false)}
            dataConfig={dataConfig}
          />
        </DialogContainer>
      )}
    </div>
  );
};

export default RequestManagement;
