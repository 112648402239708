import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApiDeactiveParam,
  CompanyTypeValue,
  EntityEnding,
  Industry,
  NationValue,
  ViewedUser,
} from '../api/types';
import {
  callApiDeactiveAccount,
  callApiEditAccount,
} from '../api/userManagement';
import { FormFieldEmail } from '../components/FormFieldEmail';
import { FormFieldPhoneNumber } from '../components/FormFieldPhoneNumber';
import { FormFieldText } from '../components/FormFieldText';
import { IconSpinner } from '../components/icons';
import { useValidateCaller } from '../hooks-ui/useValidateCaller';
import {
  generatePhone,
  RNPhoneValue,
} from '../services-business/api/generate-api-param/account';
import { generateEditUserParam } from '../services-business/api/generate-api-param/userManagement';
import { DialogSuccessFullscreen } from './DialogFormStatusFullscreen';
import ButtonCs from './ButtonCs';

type Props = {
  userInfo: ViewedUser;
  onClose: () => void;
  onEdit: () => void;
  refetch: () => void;
};

export function UserDetailAndEdit(props: Props) {
  const translation = useTranslation();
  const { validateCaller, validateAll } = useValidateCaller();
  const [nation, setNation] = useState<NationValue>(props.userInfo.llcInNation);
  // step 2
  const [email, setEmail] = useState<string>(props.userInfo.email);
  const [phone, setPhone] = useState<RNPhoneValue>(
    generatePhone(props.userInfo.codePhone, props.userInfo.phone),
  );
  const [companyType, setCompanyType] = useState<CompanyTypeValue>(
    props.userInfo.companyType,
  );
  const [firstName, setFirstName] = useState<string>(props.userInfo.firstName);
  const [lastName, setLastName] = useState<string>(props.userInfo.lastName);
  // step 3
  const [companyName, setCompanyName] = useState<string>(
    props.userInfo.companyName,
  );
  const [entityEnding, setEntityEnding] = useState<EntityEnding>(
    props.userInfo.entityEnding,
  );
  const [industry, setIndustry] = useState<Industry>(props.userInfo.industry);
  const [website, setWebsite] = useState<string>(props.userInfo.website);
  const [companyDescription, setCompanyDescription] = useState<string>(
    props.userInfo.companyDescription,
  );
  // step 4

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isRequestingEnableOrDisable, setIsRequestingEnableOrDisable] =
    useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  async function handleClickSave() {
    if (!validateAll()) {
      return;
    }
    setErrorMessage('');
    setIsSaving(true);
    try {
      const body = generateEditUserParam(
        props.userInfo.id,
        props.userInfo.enable,
        nation,
        email,
        phone,
        companyType,
        firstName,
        lastName,
        companyName,
        entityEnding,
        industry,
        website,
        companyDescription,
      );

      await callApiEditAccount(body);
      setIsSaving(false);
      props.onClose();
      props.refetch();
    } catch (e: unknown) {
      setIsSaving(false);
      setErrorMessage(e?.toString());
      console.error(e);
    }
  }

  async function changeEnableUser(isEnable: boolean) {
    setErrorMessage('');
    setIsRequestingEnableOrDisable(true);
    try {
      const body: ApiDeactiveParam = {
        idUser: props.userInfo.id,
        enable: isEnable ? 1 : 0,
      };
      await callApiDeactiveAccount(body);
      setIsRequestingEnableOrDisable(false);
      setShowSuccessModal(true);
      props.onEdit();
    } catch (e: unknown) {
      setIsRequestingEnableOrDisable(false);
      setErrorMessage(e?.toString());
      console.error(e);
    }
  }

  return (
    <div className={'flex flex-col gap-y-8'}>
      <div className={'flex flex-row justify-between items-end'}>
        <div className={'flex flex-row gap-3 items-end'}>
          <p className="text-h4 text-center">{translation.t('User Detail')}</p>
        </div>
        {props.userInfo.enable ? (
          <button
            onClick={changeEnableUser.bind(undefined, false)}
            className="px-4 py-2 flex justify-center items-center gap-2 bg-danger text-white font-semibold rounded-lg"
          >
            {translation.t('Inactive')}
            {isRequestingEnableOrDisable && <IconSpinner />}
          </button>
        ) : (
          <button
            onClick={changeEnableUser.bind(undefined, true)}
            className="px-4 py-2 flex justify-center items-center gap-2 bg-success text-white font-semibold rounded-lg"
          >
            {translation.t('Active')}
            {isRequestingEnableOrDisable && <IconSpinner />}
          </button>
        )}
      </div>
      <p className={'text-danger -my-6'}>{errorMessage}</p>
      <div className="flex flex-col gap-y-8">
        <div className="flex flex-col w-fit gap-y-2">
          <p className="text-cLg font-bold">
            {translation.t('Account information')}
          </p>
          <div className="w-1/2 border-2 border-primary"></div>
        </div>
        <FormFieldEmail
          id="accountEmail"
          isRequired
          value={email}
          onChange={setEmail}
          validateCaller={validateCaller}
        />
        <FormFieldPhoneNumber
          id={'phoneNumber'}
          placeholder={'Input number'}
          isRequired
          value={phone}
          onChange={setPhone}
          validateCaller={validateCaller}
        />
        <div className={'w-full flex gap-4'}>
          <FormFieldText
            id={'FirstName'}
            isRequired
            label="First Name"
            value={firstName}
            onChange={setFirstName}
            placeholder="Enter first name"
            validateCaller={validateCaller}
          />
          <FormFieldText
            id={'LastName'}
            isRequired
            label="Last Name"
            value={lastName}
            onChange={setLastName}
            placeholder="Enter last name"
            validateCaller={validateCaller}
          />
        </div>
        <div className="flex justify-end">
          <div className="flex gap-2">
            <button
            onClick={props.onClose}
              className="px-4 py-2 flex justify-center items-center gap-2 border-primary border-2 text-primary font-semibold rounded-lg"
            >
              {translation.t('Cancel')}
            </button>
            <button
              onClick={handleClickSave}
              className="px-4 py-2 flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
            >
              {translation.t('Save')}
              {isSaving && <IconSpinner />}
            </button>
          </div>
        </div>
      </div>

      {showSuccessModal && (
        <DialogSuccessFullscreen
          onClose={() => setShowSuccessModal(false)}
          title="Update user status successfully!"
          actionElement={
            <ButtonCs onClick={() => setShowSuccessModal(false)}>
              Close
            </ButtonCs>
          }
        />
      )}
    </div>
  );
}
