import { useQuery } from '@tanstack/react-query';
import { callApiGetLandingContent } from '../../api/landingRequest';
import { KeyFactory } from '../../services-base/key-factory';

export const useApiGetLandingContent = () => {
  return useQuery({
    queryKey: KeyFactory.getContentLanding(),
    queryFn: () => callApiGetLandingContent(),
  });
};
