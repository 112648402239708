import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  title: string;
  contentMsg: string;
}

const ConfirmDialog = (props: Props) => {
  const {t} = useTranslation();
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="font-semibold border-b-2">
        {props.title}
      </DialogTitle>
      <DialogContent className="h-28 w-96 !p-6 ">
        <DialogContentText
          id="alert-dialog-description"
          className="!text-[#000000de]"
        >
          {props.contentMsg}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="!p-4">
        <button
          className="min-w-[80px] h-[42px] flex justify-center items-center gap-2 border-[1px] border-[#000000de] font-semibold rounded-lg"
          onClick={props.onClose}
        >
          {t('Cancel')}
        </button>
        <button
          className="min-w-[80px] h-[42px] flex justify-center items-center gap-2 bg-primary text-white font-semibold rounded-lg"
          onClick={props.onAccept}
          autoFocus
        >
          {t('Confirm')}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
