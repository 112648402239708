import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import ButtonCs from './ButtonCs';
import { useTranslation } from 'react-i18next';
import { DownloadIcon, IconX } from './icons';
import { getFile } from '../api/upload';
import CantViewFile from '../assets/images/CantViewFile.jpg';

interface IProps {
  urlPreview: string;
  fileType?: string;
  onClose: () => void;
  fileId?: string;
}

const MediaPreview = (props: IProps) => {
  const { urlPreview, fileType, onClose, fileId } = props;
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isError, setIsError] = useState<boolean>(false);
  const { t } = useTranslation();

  const isImgFile = fileType?.toLowerCase().includes('image');
  const isVideoFile = fileType?.toLowerCase().includes('video');

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleDownload = () => {
    if (!fileId) return;
    getFile(fileId);
  };

  const handlePreviewPDF = () => {
    return (
      <div>
        <div className={`overflow-x-auto ${!isError ? 'h-[80vh]' : ''}`}>
          <Document
            file={urlPreview}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => setIsError(true)}
            error={
              <div className="mx-auto w-full text-center">
                <div className="font-bold">{t('No preview available')}</div>
                <div className="font-bold">
                  {t('Please download file to view!')}
                </div>
                <img className="w-[300px] mt-4" src={CantViewFile} />
              </div>
            }
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
        {!isError && (
          <div className=" flex flex-col gap-2 justify-center items-center">
            <p>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </p>
            <div className="flex gap-4">
              <ButtonCs
                type="submit"
                onClick={previousPage}
                disabled={pageNumber <= 1}
              >
                {t('Previous')}
              </ButtonCs>
              <ButtonCs
                type="submit"
                onClick={nextPage}
                disabled={pageNumber === numPages}
              >
                {t('Next')}
              </ButtonCs>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handlePreviewImage = () => (
    <div className="w-[60vw] mx-4 flex justify-center items-center flex-col gap-y-8">
      <img src={urlPreview} />
    </div>
  );

  const handlePreviewVideo = () => (
    <div className="w-[60vw]">
      <video
        className="aspect-video mx-auto object-contain rounded-lg overflow-hidden"
        src={urlPreview}
        controls
      />
    </div>
  );

  return (
    <div className="p-4 flex flex-col items-end gap-4">
      <div className="flex gap-4 items-center">
        <DownloadIcon
          className="cursor-pointer scale-75"
          onClick={handleDownload}
        />
        <IconX className="cursor-pointer" onClick={onClose} />
      </div>
      {isImgFile
        ? handlePreviewImage()
        : isVideoFile
        ? handlePreviewVideo()
        : handlePreviewPDF()}
    </div>
  );
};

export default MediaPreview;
