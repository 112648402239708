import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiViewUserParam, TableData, ViewedUser } from '../api/types';
import { DialogContainer } from '../components/DialogContainer';
import { FormCreateAdminAccount } from '../components/FormCreateAdminAccount';
import { UserDetailAndEdit } from '../components/UserDetailAndEdit';
import { useValidateCaller } from '../hooks-ui/useValidateCaller';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useApiGetUsers } from '../hooks/api/user';
import { generateFormatDate } from '../services-ui/date';
import { Tab } from '@headlessui/react';
import { AuthContext } from '../contexts/AuthContextProvider';
import { StatusBadge } from '../components/StatusBadge';
import { Status } from '../constants/StatusBadge';
import { IconPen } from '../components/icons';
import { FormFieldText } from '../components/FormFieldText';
import ButtonCs from '../components/ButtonCs';
import { handleExportData } from '../services-base/api';
import { TAB_EXPORT } from '../constants/global';

const ROLES: Record<string, string> = {
  ROLE_USER: 'Customer',
  ROLE_ADMIN: 'Admin',
  ROLE_MODERATOR: 'Staff',
};

const DEFAULT_SEARCH = {
  email: '',
  phone: '',
  fullName: '',
};

type Props = {};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export function UsersContent(props: Props) {
  const translation = useTranslation();
  const { validateCaller } = useValidateCaller();
  const [dataSearch, setDataSearch] = useState<{
    [key in string]: string;
  }>(DEFAULT_SEARCH);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });

  const [userClicked, setUserClicked] = useState<ViewedUser>();
  const [shouldShowCreateUser, setShouldShowCreateUser] = useState<boolean>();
  const { user } = useContext(AuthContext);

  const {
    data,
    isLoading: gettingUsers,
    refetch,
  } = useApiGetUsers({
    page: paginationModel.page,
    size: paginationModel.pageSize,
    email: dataSearch.email,
    phone: dataSearch.phone,
    fullName: dataSearch.fullName,
    userTab: currentTab + 1
  });

  async function handleEdit() {
    const { data } = await refetch();
    const updatedUserClicked = data?.content.find(
      (user) => user.id === userClicked?.id,
    );
    if (updatedUserClicked) {
      setUserClicked(updatedUserClicked);
    }
  }

  async function handleCreated() {
    setShouldShowCreateUser(false);
    refetch();
  }

  const handleClear = () => {
    setDataSearch(DEFAULT_SEARCH);
    setTimeout(refetch);
  };

  const handleSearch = () => {
    refetch();
  };

  const handleExport = () => handleExportData(TAB_EXPORT.USER);

  // TODO: add i18n for columns
  const userColumns: GridColDef<ViewedUser>[] = [
    {
      field: 'rowIndex',
      headerName: 'No.',
      width: 100,
      valueGetter: (params) =>
        params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
    },
    { field: 'userId', headerName: 'User ID', width: 70, sortable: false },
    {
      field: 'enable',
      headerName: 'Status',
      sortable: false,
      type: 'string',
      width: 200,
      renderCell: (params) => {
        const status = Boolean(Number(params.row.enable))
          ? 'Active'
          : 'Inactive';
        return <StatusBadge status={status as Status}></StatusBadge>;
      },
    },
    {
      field: 'name',
      headerName: 'Full Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: false,
      type: 'string',
      width: 200,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.codePhone || ''} ${params.row.phone || ''}`,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'string',
      width: 200,
      valueGetter: (params) => {
        return generateFormatDate(new Date(params.row.createdAt));
      },
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      sortable: false,
      type: 'string',
      width: 200,
      valueGetter: (params: GridValueGetterParams<any>) => {
        return params.row.updatedBy;
      },
    },
    {
      field: 'Option',
      headerName: 'Option',
      sortable: false,
      type: 'string',
      width: 150,
      renderCell: (row) => (
        <button
          className=" rounded bg-primary px-10 py-1 svg-white"
          onClick={() => {
            setUserClicked(row.row);
          }}
        >
          <IconPen className="fill-white" />
        </button>
      ),
    },
  ];

  return (
    <div className={'w-full grow flex flex-col p-3'}>
      <div
        className={
          'flex flex-col grow overflow-auto bg-white rounded justify-start items-center py-6 px-4 sm:px-8'
        }
      >
        <p className={'text-h4 w-full text-start mb-6'}>
          {translation.t('User Management')}
        </p>
        <div
          className={
            'w-full flex flex-row justify-end items-center gap-10 mb-4'
          }
        >
          <div
            className={
              'w-full flex flex-row justify-between items-center gap-10 mb-4'
            }
          >
            <div
              className={
                'w-full flex flex-row justify-start items-end gap-4 mb-4'
              }
            >
              <FormFieldText
                label={translation.t('Email')}
                id="email"
                validateCaller={validateCaller}
                onChange={(e) => setDataSearch({ ...dataSearch, email: e })}
                value={dataSearch.email}
                placeholder={translation.t('Input email')}
              />
              <FormFieldText
                label={translation.t('Phone')}
                id="phone"
                validateCaller={validateCaller}
                onChange={(e) => setDataSearch({ ...dataSearch, phone: e })}
                value={dataSearch.phone}
                placeholder={translation.t('Input phone number')}
              />
              <FormFieldText
                label={translation.t('Full Name')}
                id="fullName"
                validateCaller={validateCaller}
                onChange={(e) => setDataSearch({ ...dataSearch, fullName: e })}
                value={dataSearch.fullName}
                placeholder={translation.t('Input full name')}
              />
              <div className="flex gap-2">
                <ButtonCs
                  type="button"
                  className="bg-transparent border border-surface text-primary"
                  onClick={handleClear}
                  isLoading={gettingUsers}
                >
                  {translation.t('Reset')}
                </ButtonCs>
                <ButtonCs
                  isLoading={gettingUsers}
                  type="submit"
                  onClick={handleSearch}
                >
                  {translation.t('Apply')}
                </ButtonCs>
              </div>
            </div>
            <div className="flex gap-2">
              {user?.roles[0]?.name === 'ROLE_ADMIN' && (
                <ButtonCs
                  onClick={setShouldShowCreateUser.bind(undefined, true)}
                  className="w-[130px]"
                >
                  {translation.t('Create new')}
                </ButtonCs>
              )}
              <ButtonCs
                type="submit"
                className="h-[40px]"
                onClick={handleExport}
              >
                {translation.t('Export')}
              </ButtonCs>
            </div>
          </div>
        </div>
        <div className={'w-full grow '} key={new Date().toString()}>
          <Tab.Group
            defaultIndex={currentTab}
            onChange={(e) => {
              setCurrentTab(e);
              setTimeout(refetch);
            }}
          >
            <Tab.List className="flex gap-1 w-[156px]">
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white bg-[#aed3da]',
                    selected ? '!bg-[#094752] text-white shadow' : '',
                  )
                }
              >
                {translation.t('User List')}
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white bg-[#aed3da]',
                    selected ? '!bg-[#094752] text-white shadow' : '',
                  )
                }
              >
                {translation.t('Staff List')}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="mt-3">
                <DataGrid
                  paginationMode="server"
                  rows={data?.content || []}
                  columns={userColumns}
                  pageSizeOptions={[25]}
                  sx={{
                    height: 'calc(100vh - 300px)',
                  }}
                  rowCount={data?.totalElements || 0}
                  paginationModel={paginationModel}
                  sortingMode="server"
                  onPaginationModelChange={(model) => setPaginationModel(model)}
                  loading={gettingUsers}
                />
              </Tab.Panel>
              <Tab.Panel className="mt-3">
                <DataGrid
                  paginationMode="server"
                  rows={data?.content || []}
                  columns={userColumns}
                  pageSizeOptions={[25]}
                  rowCount={data?.totalElements || 0}
                  sx={{
                    height: 'calc(100vh - 300px)',
                  }}
                  paginationModel={paginationModel}
                  sortingMode="server"
                  onPaginationModelChange={(model) => setPaginationModel(model)}
                  loading={gettingUsers}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      {userClicked && (
        <DialogContainer
          isAutoSize
          onClose={(shouldOpen: boolean) =>
            !shouldOpen && setUserClicked(undefined)
          }
        >
          <div className="w-full max-w-[1000px] justify-center items-center py-8 px-4 flex flex-col">
            <div className="w-full mx-4 flex justify-center items-center flex-col gap-y-8">
              <UserDetailAndEdit
                userInfo={userClicked}
                onClose={setUserClicked.bind(undefined, undefined)}
                onEdit={handleEdit}
                refetch={refetch}
              />
            </div>
          </div>
        </DialogContainer>
      )}
      {shouldShowCreateUser && (
        <DialogContainer
          isAutoSize
          onClose={(shouldOpen: boolean) =>
            !shouldOpen && setShouldShowCreateUser(false)
          }
        >
          <div className="w-full max-w-[1600px] justify-center items-center py-8 px-4 flex flex-col">
            <div className="w-full mx-4 flex justify-center items-center flex-col gap-y-8">
              <FormCreateAdminAccount onCreated={handleCreated} />
            </div>
          </div>
        </DialogContainer>
      )}
    </div>
  );
}
