import { useMutation, useQuery } from '@tanstack/react-query';
import {
  callApiExportData,
  callApiGetBankAccounts,
  callApiGetListService,
  callApiGetServiceDetail,
  callApiSendPaymentReminder,
  callApiSendRequiredDocumentReminder,
  callApiUpdateAdminRemark,
  callApiUpdatePic,
  callApiUpdateStatusService,
  callApiUploadCustomerDocument,
  callApiUploadStatusStep,
} from '../api/serviceManagement';
import { ApiPagingParam } from '../api/types';
import KeyFactory from '../services-base/reactQuery/keyFactory';
import {
  ExtraOptionQuery,
  mergeQueryOptions,
} from '../services-base/reactQuery/queryOption';

export function useApicalGetListService(
  param: ApiPagingParam,
  extraOption?: ExtraOptionQuery,
) {
  return useQuery(
    mergeQueryOptions(
      {
        queryKey: [KeyFactory.getPaidService(), param],
        queryFn: () => callApiGetListService(param),
        enabled: !!extraOption?.enabled,
      },
      extraOption,
    ),
  );
}

export function useApiGetServiceDetail(
  id: number | string,
  extraOption?: ExtraOptionQuery,
) {
  return useQuery(
    mergeQueryOptions(
      {
        queryKey: KeyFactory.getServiceDetail(id),
        queryFn: () => callApiGetServiceDetail(id),
        enabled: !!extraOption?.enabled,
      },
      extraOption,
    ),
  );
}

export function useApiServiceUploadServiceResult() {
  return useMutation({
    mutationFn: callApiUploadCustomerDocument,
  });
}

export function useApiServiceUpdateAdminRemark() {
  return useMutation({
    mutationFn: callApiUpdateAdminRemark,
  });
}

export function useApiServiceUploadStatusStep() {
  return useMutation({
    mutationFn: callApiUploadStatusStep,
  });
}

export function useApiServiceUpdatePic() {
  return useMutation({
    mutationFn: callApiUpdatePic,
  });
}

export function useApiServiceStatusUpdate() {
  return useMutation({
    mutationFn: callApiUpdateStatusService,
  });
}
export function useApiSendPaymentReminder() {
  return useMutation({
    mutationFn: callApiSendPaymentReminder,
  });
}

export function useApiSendRequiredDocumentReminder() {
  return useMutation({
    mutationFn: callApiSendRequiredDocumentReminder,
  });
}

export function useApiExportDataService() {
  return useMutation({
    mutationFn: callApiExportData,
  });
}

export const useApiGetBanks = () => {
  return useQuery({
    queryKey: KeyFactory.getCurrency(),
    queryFn: callApiGetBankAccounts,
  });
};
