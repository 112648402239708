import { useQuery } from '@tanstack/react-query';
import { KeyFactory } from '../../services-base/key-factory';
import { callApiGetRequest } from '../../api/requestManagement';

export type RequestManagementProps = {
  page: number;
  size: number;
  searchStr: string;
  fromDate: string;
  toDate: string;
};

export const useApiGetRequestManagement = (
  parmas: RequestManagementProps,
  path: string,
) => {
  return useQuery({
    queryKey: KeyFactory.getCompany(parmas.page, parmas.size, path),
    queryFn: () => callApiGetRequest(parmas, path),
  });
};
